import { createAction, props } from "@ngrx/store";
import { EmbeddingFile } from "app/app.model";

export const getEmbeddingFile = createAction("[EmbeddingFile API] Get Embedding File", props<{ id: string }>());

export const setEmbeddingFile = createAction(
  "[EmbeddingFile] Set Embedding File",
  props<{ embeddingFile: EmbeddingFile[] }>(),
);

export const addEmbeddingFile = createAction(
  "[EmbeddingFile] Add Embedding File",
  props<{ brainId: string; embeddingFile: EmbeddingFile[]; newlyAdded?: boolean }>(),
);

export const updateEmbeddingFile = createAction(
    "[EmbeddingFile] Update Embedding File",
    props<{ brainId: string; embeddingFile: Partial<EmbeddingFile> }>(), // `Partial` allows selective updates
);

export const updateNewlyAddedStatus = createAction(
  "[EmbeddingFile] Update Newly Added Status",
  props<{ brainId: string }>(),
);

export const removeEmbeddingFile = createAction(
  "[EmbeddingFile] Remove Embedding File",
  props<{ brainId: string; embeddingFile: EmbeddingFile[] }>(),
);

export const deleteEmbeddingFiles = createAction(
  "[EmbeddingFile API] Batch Delete Embedding Files",
  props<{ brainId: string; content: EmbeddingFile[] }>(),
);

export const clearEmbeddingFileState = createAction("[Embedding File] Clear State");
