import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface LoaderState {
  isLoading: boolean;
  loaderType: string;  // Add any other state information you need
}

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private defaultState: LoaderState = {
    isLoading: false,
    loaderType: "home",
  };

  private loaderStateSubject = new BehaviorSubject<LoaderState>(this.defaultState);
  loaderState$ = this.loaderStateSubject.asObservable();


  showLoader(loaderType = "home") {
    this.loaderStateSubject.next({ isLoading: true, loaderType });
  }

  hideLoader() {
    this.loaderStateSubject.next({ isLoading: false, loaderType: "home" });
  }
}
