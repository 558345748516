import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Subscription } from "rxjs/internal/Subscription";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { selectBrain } from "app/store/selectors/brain.selector";
import { EmbeddingFile } from "app/app.model";
import { selectEmbeddingFile } from "app/store/selectors/embedding-file.selector";
import { UserData } from "app/pages/home/home.model";
import { selectUser } from "app/store/selectors/user.selector";
import { Conversation } from "app/api-models/conversation.models";
import { selectConversation } from "app/store/selectors/conversation.selector";

@Component({
  selector: "app-base-component",
  standalone: true,
  imports: [],
  templateUrl: "./base-component.component.html",
  styleUrls: ["./base-component.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnDestroy {
  protected cdr = inject(ChangeDetectorRef);
  protected subs$ = new Subscription();
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected snackbar = inject(MatSnackBar);
  protected transloco = inject(TranslocoService);
  protected destroy$ = new Subject<void>();
  protected store = inject(Store);
  protected brain$: Observable<Brain | null>;
  protected embeddingFiles$: Observable<EmbeddingFile[] | null>;
  protected user$: Observable<UserData | null>;
  protected chats$: Observable<Conversation[] | null>;

  constructor() {
    this.brain$ = this.store.select(selectBrain);
    this.embeddingFiles$ = this.store.select(selectEmbeddingFile);
    this.user$ = this.store.select(selectUser);
    this.chats$ = this.store.select(selectConversation);
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  successMsg(
    msg: string,
    action: string | undefined = undefined,
    duration = 5000,
    isContentExceeded = false,
    isQuestionExceeded = false,
  ) {
    if (!isContentExceeded && !isQuestionExceeded) {
      this.snackbar.open(msg, action, {
        duration,
        panelClass: ["green-snackbar"],
      });
    } else {
      this.snackbar.open(msg, action, {
        duration,
        panelClass: ["green-snackbar-bottom"],
      });
    }
  }

  errorMsg(msg: string, action: string | undefined = undefined, duration = 5000) {
    this.snackbar.open(msg, action, {
      duration,
      panelClass: ["red-snackbar"],
    });
  }
}
