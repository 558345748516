import { UserData } from "app/pages/home/home.model";
import { Action, createReducer, on } from "@ngrx/store";
import { clearUser, setUser } from "app/store/actions/user.actions";

export interface UserState {
  user: UserData | null;
}

export const initialState: UserState = {
  user: null,
};

export const userReducer = createReducer(
  initialState,
  on(setUser, (state, { user }) => ({
    ...state,
    user,
  })),
  on(clearUser, (state) => ({ ...state, user: null })),
);

export function reducer(state = initialState, action: Action): UserState {
  return userReducer(state, action);
}
