/* eslint-disable max-lines */
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile, EmbeddingFileStatus } from "app/app.model";
import { AddedBrainContentComponent } from "app/core/components/added-brain-content/added-brain-content.component";
import { CustomValidator } from "app/core/modules/validation-message/custom-validator";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { MyBrainPlans, UserData, UserDataLimits } from "app/pages/home/home.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { UsersService } from "app/services/users.service";
import { map, mergeMap, of, takeUntil } from "rxjs";
import { BaseHttpComponent } from "../base-http/base-http.component";
import {
  addEmbeddingFile,
  deleteEmbeddingFiles,
  removeEmbeddingFile,
  updateEmbeddingFile
} from "app/store/actions/embedding-file.action";
import { setUser } from "app/store/actions/user.actions";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { PollingService } from "app/core/services/polling.service";

@Component({
  selector: "app-web-page-upload",
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormsModule,
    ValidationMessageModule,
    MatProgressSpinnerModule,
    BrainLimitDirective,
    AddedBrainContentComponent,
    MatExpansionModule,
  ],
  templateUrl: "./web-page-upload.component.html",
  styleUrls: ["./web-page-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WebPageUploadComponent extends BaseHttpComponent implements OnInit {
  constructor(
    private embeddingFileService: EmbeddingFileService,
    private translocoService: TranslocoService,
    private userService: UsersService,
    private pollingService: PollingService,
    private userLimitService: UserDataLimitsService
  ) {
    super();
  }

  @Input() fileUploading = false;
  @Input() webCount = 0;
  @Output() isUploading = new EventEmitter<boolean>();

  // eslint-disable-next-line quotes
  webPageUrl = new FormControl<string>("", { validators: [CustomValidator.url()] });
  addAllPages = false;
  errorMessage = "";
  contents: EmbeddingFile[] = [];
  selectedBrain: Brain | null = null;
  entireWebsiteCrawlInProgress = false;
  userDataLimits!: UserDataLimits;
  userData?: UserData;
  uploading = false;
  allBrainContents: EmbeddingFile[] = [];

  ngOnInit(): void {
    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.selectedBrain = brain;
        this.cdr.markForCheck();
      }
    });

    this.embeddingFiles$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.allBrainContents = data;
        this.contents = this.allBrainContents.filter(
          (files) =>
            files.newlyAdded === true && (files.contentType === BrainContentType.Website ||
              files.contentType === BrainContentType.Link));
      }
    });

    this.user$.subscribe((userData) => {
      if (userData) {
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
        this.userData = userData;
        this.cdr.markForCheck();
      }
    });
  }

  async validateUrl() {
    this.errorMessage = "";
    if (this.webPageUrl.invalid || !this.webPageUrl.value) {
      return;
    }
    const brainContent = this.allBrainContents;
    if (brainContent.find((content) => content.webpage === this.webPageUrl.value)) {
      this.errorMessage = this.transloco.translate("addMyBrainContent.websiteAdded");
      return;
    }

    if (this.addAllPages) {
      this.uploadSite();
    } else {
      this.uploadSinglePage();
    }
  }

  uploadSite() {
    AppStore.isIndexing$.next(true);
    this.isUploading.emit(true);
    this.entireWebsiteCrawlInProgress = true;

    const website = {
      projectId: this.selectedBrain?.id,
      status: EmbeddingFileStatus.Indexing,
      contentType: BrainContentType.Website,
      webpage: this.webPageUrl.value,
      metadata: {
        title: this.webPageUrl.value,
      },
      failed: false,
    } as EmbeddingFile;
    this.store.dispatch(
      addEmbeddingFile({ brainId: this.selectedBrain?.id as string, embeddingFile: [website], newlyAdded: true }),
    );
    this.cdr.markForCheck();
    this.isUploading.emit(true);

    this.embeddingFileService.addWebsite(website).subscribe((res) => {
      if (res && res.isSuccess && res.data) {
        this.store.dispatch(addEmbeddingFile({ brainId: this.selectedBrain?.id as string, embeddingFile: [website] }));
        this.isUploading.emit(false);
        this.cdr.markForCheck();
      } else {
        if (!(res.isSuccess && res.data) && res.message && res.message.length) {
          this.errorMsg(this.translocoService.translate("validation." + res.message[0]));
          this.store.dispatch(
            removeEmbeddingFile({
              brainId: this.selectedBrain?.id as string,
              embeddingFile: [website] as EmbeddingFile[],
            }),
          );
          this.isUploading.emit(false);
        }
      }
    });
  }
  uploadSinglePage() {
    this.isUploading.emit(true);
    this.uploading = true;
    const webPageContent = {
      contentType: BrainContentType.Link,
      webpage: this.webPageUrl.value,
      status: EmbeddingFileStatus.Processing,
      fileName: this.webPageUrl.value,
      metadata: {
        title: this.webPageUrl.value,
      },
      failed: false,
      projectId: this.selectedBrain?.id,
    } as EmbeddingFile;
    this.store.dispatch(
      addEmbeddingFile({
        brainId: this.selectedBrain?.id as string,
        embeddingFile: [webPageContent],
        newlyAdded: true,
      }),
    );
    this.cdr.markForCheck();
    let currentEmbeddingFile = webPageContent;
    this.embeddingFileService
      .addWebpage({
        projectId: this.selectedBrain?.id,
        status: EmbeddingFileStatus.Processing,
        webpage: webPageContent.webpage,
        contentType: BrainContentType.Link,
        failed: false,
      } as EmbeddingFile)
      .pipe(
        mergeMap((resp) => {
          this.webPageUrl.setValue("");
          if (!(resp.isSuccess && resp.data)) {
            this.errorMsg(this.translocoService.translate("validation.invalidWebpage"));
            this.store.dispatch(
              removeEmbeddingFile({
                brainId: this.selectedBrain?.id as string,
                embeddingFile: [currentEmbeddingFile] as EmbeddingFile[],
              }),
            );
            this.contents = this.contents.filter((c) => c.webpage !== webPageContent.webpage);
            this.uploading = false;
            this.isUploading.emit(false);
            this.cdr.markForCheck();
            return of(null);
          }
          this.uploading = false;
          this.isUploading.emit(false);
          this.updateCurrentPage(resp.data);
          currentEmbeddingFile = resp.data;
          return this.userService.get();
        }),
      )
      .pipe(
        mergeMap((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            this.store.dispatch(setUser({ user: resp.data }));
            this.cdr.markForCheck();
            return this.embeddingFileService.ingestFile(currentEmbeddingFile);
          } else {
            return of(null);
          }
        }),
      )
      .pipe(
        mergeMap((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            this.updateCurrentPage(resp.data);
            return this.pollingService.pollingEmbeddingFileStatus(resp.data).pipe(
              mergeMap((pollingResult) => {
                if (pollingResult.isSuccess && pollingResult.data) {
                  return this.userService.get().pipe(
                    map((userResponse) => ({
                      pollingResult,
                      userResponse,
                    })),
                  );
                }
                return of({
                  pollingResult,
                  userResponse: null,
                });
              }),
            );
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (result) => {
          if (result && result.pollingResult?.isSuccess && result.pollingResult.data) {
            this.updateCurrentPage(result.pollingResult.data);
          }
        },
        error: (err) => {
          console.error(err);
          this.isUploading.emit(false);
          this.uploading = false;
          this.cdr.markForCheck();
        },
        complete: () => {
          this.isUploading.emit(false);
          this.uploading = false;
          this.cdr.markForCheck();
        },
      });
  }

  updateCurrentPage(embeddingFile: EmbeddingFile) {
    this.store.dispatch(
        updateEmbeddingFile({
        brainId: this.selectedBrain?.id as string,
        embeddingFile: embeddingFile,
      }),
    );
    this.cdr.markForCheck();
  }

  deleteContent(currentContent: EmbeddingFile) {
    const content = this.contents.find((x) => x.webpage === currentContent.webpage);
    if (!content) {
      return;
    }

    this.store.dispatch(deleteEmbeddingFiles({ brainId: this.selectedBrain?.id as string, content: [content] }));
    this.cdr.markForCheck();
  }

  protected readonly MyBrainPlans = MyBrainPlans;
}
