import { Pipe, PipeTransform } from "@angular/core";
import { ProgressData } from "app/app.model";

@Pipe({
  name: "extractProgressData",
  standalone: true,
})
export class ExtractProgressDataPipe implements PipeTransform {
  transform(progressData: ProgressData[], fileName: string | undefined): ProgressData | undefined {
    return progressData.find((x) => x.FileName === fileName);
  }
}
