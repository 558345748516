import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, switchMap } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { StatusQueryType } from "app/app.model";
import {
  deleteEmbeddingFiles,
  getEmbeddingFile,
  removeEmbeddingFile,
  setEmbeddingFile,
} from "app/store/actions/embedding-file.action";
import { UsersService } from "app/services/users.service";
import { getUser } from "app/store/actions/user.actions";

@Injectable()
export class EmbeddingFileEffects {
  constructor(
    private actions$: Actions,
    private embeddingFileService: EmbeddingFileService,
  ) {}

  loadBrainEmbeddingFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmbeddingFile),
      mergeMap(({ id }) =>
        this.embeddingFileService.getBrainEmbeddingFiles(id, StatusQueryType.All).pipe(
          map((response) => {
            const embeddingFiles = response.data?.results || [];
            return setEmbeddingFile({ embeddingFile: embeddingFiles });
          }),
          catchError((error) => {
            console.error("Error loading Embedding file:", error);
            return of();
          }),
        ),
      ),
    ),
  );

  deleteEmbeddingFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEmbeddingFiles),
      mergeMap(({ brainId, content }) =>
        this.embeddingFileService.batchDelete(content).pipe(
          switchMap((response) => {
            if (response && response.isSuccess) {
              const removeAction = removeEmbeddingFile({
                brainId,
                embeddingFile: content,
              });
              return [removeAction, getUser()];
            }
            return of();
          }),
          catchError((error) => {
            console.error("Batch delete failed:", error);
            return of();
          }),
        ),
      ),
    ),
  );
}
