import { CommonModule, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile, EmbeddingFileExtension, EmbeddingFileStatus } from "app/app.model";
import { BaseComponent } from "app/core/components/base-component/base-component.component";
import { getFileIconPath } from "app/core/functions/helper-functions";
import { ExtractProgressDataPipe } from "app/pipes/extract-progress-data.pipe";
import { LastNCharsPipe } from "app/pipes/last-nchars.pipe";

@Component({
  selector: "app-added-brain-content",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatTooltipModule,
    LastNCharsPipe,
    ExtractProgressDataPipe,
  ],
  templateUrl: "./added-brain-content.component.html",
  styleUrls: ["./added-brain-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddedBrainContentComponent extends BaseComponent implements OnInit {
  @Input() embeddingFile!: EmbeddingFile;
  @Output() deleteContent = new EventEmitter<EmbeddingFile>();
  EmbeddingFileExtension = EmbeddingFileExtension;
  processedFiles: Set<string> = new Set();
  public zipUploadProgress$ = AppStore.zipUploadProgress$;

  constructor() {
    super();
  }

  ngOnInit() {
    AppStore.zipUploadProgress$.subscribe((zipProgress) => {
      if (zipProgress) {
        zipProgress.forEach((file) => {
          if (
            file.FileName === this.embeddingFile.fileName &&
            file.Progress === 100 &&
            this.embeddingFile.status === 6 &&
            !this.processedFiles.has(file.FileName)
          ) {
            this.processedFiles.add(file.FileName);
            this.successMsg(this.transloco.translate("uploadSuccessful", { fileName: file.FileName }));
          }
        });
      }
    });
  }

  deleteContentData() {
    this.deleteContent.emit(this.embeddingFile);
  }

  protected readonly EmbeddingFileStatus = EmbeddingFileStatus;
  protected readonly BrainContentType = BrainContentType;
  getFileIconPath = getFileIconPath;
}
