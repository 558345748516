<h2 mat-dialog-title class="dialog-head">
  <div class="dialog-head flex justify-between items-center" *transloco="let t; read: 'addBrainName'">
    <div class="text-lg font-medium text-black-dark">
      {{ t("title") }}
    </div>
  </div>
</h2>
<mat-dialog-content class="mat-typography" *transloco="let t; read: 'addBrainName'">
  <div class="w-full px-0 md:w-[420px]">
    <div class="text-base text-black-lighter pb-4">
      {{ t("description") }}
    </div>
    <div class="flex-col justify-start">
      <form [formGroup]="brainDetailForm">
        <div class="full relative">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ t("inputLabel") }}</mat-label>
            <input
              [formControl]="brainDetailForm.controls.brainName"
              type="text"
              matInput
              (keydown.enter)="onClick()"
            />
          </mat-form-field>
          <app-validation-message
            [formControlVal]="brainDetailForm.controls.brainName"
            labelText="Brain name"
            [error]="overriteMessageError"
          ></app-validation-message>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *transloco="let t; read: 'addBrainName'">
  <button
  mat-raised-button
  extended
  color="primary"
  (click)="onClick()"
  [disabled]="!brainDetailForm.valid"
  class="flex-auto px-0"
  mat-dialog-close
  >
    {{ t("buttonLabel") }}
  </button>
</mat-dialog-actions>