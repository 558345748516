<div class="fixed inset-0 flex items-center justify-center">
  <div class="bg-white rounded-lg overflow-hidden w-full max-w-[90%] lg:max-w-[1024px] max-h-full">
    <div class="flex flex-col max-h-[70vh] p-4 gap-4">
      <div class="overflow-y-auto flex-grow pb-1">
        <h2 class="dialog-head flex-none">
          <div class="dialog-head flex justify-between items-center" *transloco="let t; read: 'addMyBrainContent'">
            <div class="text-lg font-medium text-black-dark">
              {{ t("addYourContent") }}
            </div>
            <button mat-button [disabled]="isUploading" (click)="closeDialog()">
              <img src="./assets/images/icons/close.svg" />
            </button>
          </div>
        </h2>
        <div class="flex-1 lg:overflow-x-auto !max-h-none">
          <div class="w-full lg:min-w-[980px] lg:overflow-hidden" *transloco="let t; read: 'addMyBrainContent'">
            <div class="flex gap-2 flex-col lg:flex-row">
              <div class="w-full lg:w-1/2">
                <app-web-page-upload
                  (isUploading)="isUploadingEvent($event)"
                  [webCount]="this.webCount"
                  [fileUploading]="isUploading"
                  #webPageUploadComponent
                ></app-web-page-upload>
              </div>
              <div class="w-full lg:w-1/2">
                <app-file-upload
                  (isUploading)="isUploadingEvent($event)"
                  [fileCount]="this.fileCount"
                  [webPageUploading]="isUploading"
                  #fileUploadComponent
                ></app-file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <app-user-limit
          [isHidden]="isHidden"
          (updateIsHidden)="updateIsHidden($event)"
          class="w-full flex-none"
          (shouldCloseDialog)="shouldCloseDialog()"
        ></app-user-limit>
        <div *transloco="let t; read: 'addMyBrainContent'" class="flex-none bottom-0 bg-white z-10">
          <mat-dialog-actions align="end">
            <button mat-button (click)="closeDialog()" class="px-0" [disabled]="isUploading">
              {{ t("cancel") }}
            </button>
            <button
              mat-raised-button
              extended
              color="primary"
              (click)="addContent()"
              [disabled]="!isNewlyAdded || areAllFilesInErrorState() || isUploading"
              class="flex flex-row-reverse"
            >
              {{ t("addContent") }}
              <mat-icon class="!ml-1.5">arrow_forward</mat-icon>
            </button>
          </mat-dialog-actions>
        </div>
      </div>
    </div>
  </div>
</div>
