interface WebAppInterface {
    setKeyboardOverlay() : void;
    setKeyboardDefault() : void;
}

export function isMobileInterfaceDefined() {
    return typeof mobileappLeftSidebarInterface !== 'undefined';
}

export function getMobileappLeftSidebarInterface() {
    return mobileappLeftSidebarInterface;
}
  
declare var mobileappLeftSidebarInterface: WebAppInterface; // interface is defined by the mobile apps
