import { createAction, props } from "@ngrx/store";
import { Brain } from "app/pages/dashboard/dashboard.model";

export const getBrain = createAction("[Brain API] Get Brain", props<{ id: string; isAuth: boolean }>());

export const updateBrain = createAction("[Brain API] Update Brain", props<{ brain: Brain }>());

export const deleteBrain = createAction("[Brain API] Delete Brain", props<{ id: string; embeddingFilesIds: string[] }>());
export const deleteBrainSuccess = createAction("[Brain] Delete Brain Success");
export const deleteBrainFailure = createAction("[Brain] Delete Brain Failure", props<{ error: any }>());

export const setBrain = createAction("[Brain] Set Brain", props<{ brain: Brain }>());

export const clearBrain = createAction("[Brain] Clear Brain");
