import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private client: LDClient.LDClient | null = null;
  private flags = new BehaviorSubject<Record<string, any>>({});

  flags$ = this.flags.asObservable();

  initialize(clientSideID: string, context: any): void {
    this.client = LDClient.initialize(clientSideID, context);

    this.client.on('ready', () => {
      const allFlags = this.client?.allFlags();
      if (allFlags) {
        this.flags.next(allFlags);
      }
    });

    this.client.on('change', (changes) => {
      const updatedFlags = { ...this.flags.getValue() };
      for (const [key, value] of Object.entries(changes)) {
        updatedFlags[key] = (value as { current: unknown }).current;
      }
      this.flags.next(updatedFlags);
    });
  }

  // Get a specific flag value dynamically
  getFlagValue(flagKey: string, defaultValue: any): any {
    return this.client?.variation(flagKey, defaultValue) ?? defaultValue;
  }

  // Observable for a specific flag (if you want to watch changes)
  watchFlag(flagKey: string, defaultValue: any) {
    return this.flags$.pipe(map(flags => flags[flagKey] ?? defaultValue));
  }
}