/* eslint-disable max-lines */
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, NgSwitchCase } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterLink, RouterOutlet } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import {
  BrainContentType,
  CrawlWebSiteStatus,
  EmbeddingFile,
  EmbeddingFileStatus,
  StatusQueryType,
} from "app/app.model";
import { CONTENT_ROUTE_ID, DASHBOARD_ROUTE, SHARE_BRAIN_ROUTE_ID } from "app/const/app-constant";
import { AddMyBrainContentComponent } from "app/core/components/add-my-brain-content/add-my-brain-content.component";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { BrainNameDialogComponent } from "app/core/components/brain-name-dialog/brain-name-dialog.component";
import { ConfirmDialogComponent } from "app/core/components/confirm-dialog/confirm-dialog.component";
import { SimpleConfirmationPopupComponent } from "app/core/components/simple-confirmation-popup/simple-confirmation-popup.component";
import {
  getCurrentYear,
  isRegularUser,
  redirectToAffiliates,
  replaceParamsWithValue,
} from "app/core/functions/helper-functions";
import {
  getMobileappLeftSidebarInterface,
  isMobileInterfaceDefined,
} from "app/core/modules/mobile-interfaces/left-sidebar-interface";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { MyBrainPlans, UserData, UserDataLimits } from "app/pages/home/home.model";
import { PipesModule } from "app/pipes/pipes.module";
import { getEmbeddingFile } from "app/store/actions/embedding-file.action";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { UsersService } from "app/services/users.service";
import { LoaderService } from "app/core/components/loader/loader.service";
import { ScrollService } from "app/services/scroll.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { debounceTime, distinctUntilChanged, map, take, takeUntil, tap } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { deleteBrain, deleteBrainSuccess, getBrain, updateBrain } from "app/store/actions/brain.action";
import { getConversation } from "app/store/actions/conversation.action";

@Component({
  selector: "app-left-sidebar",
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    MatDialogModule,
    AddMyBrainContentComponent,
    AsyncPipe,
    TranslocoModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ConfirmDialogComponent,
    ValidationMessageModule,
    RouterLink,
    PipesModule,
    NgClass,
    NgSwitchCase,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    MatSlideToggleModule,
    FormsModule,
    MatIconModule,
    RouterOutlet,
    NgStyle,
    MatTooltipModule,
  ],
  templateUrl: "./left-sidebar.component.html",
  styleUrls: ["./left-sidebar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSidebarComponent extends BaseHttpComponent implements OnInit, OnDestroy {
  brainContents: EmbeddingFile[] = [];
  processingBrainContents: EmbeddingFile[] = [];
  isProcessingBrainContents = false;
  selectedBrain?: Brain;
  displayBrainName = false;
  form;
  brainProjectName? = "";
  brainId = "";
  status = EmbeddingFileStatus;
  isNameAutomatic = false;
  userDataLimits!: UserDataLimits;
  sidebarActive = false;
  webCount = 0;
  fileCount = 0;
  userData!: UserData;
  loading = false;
  panelSettingsState = false;
  panelConfigState = false;
  settingsForm!: FormGroup;
  private initialFormSet = false;
  refreshDataInProcess = false;
  @Input() isContentPage = false;
  @ViewChild("primaryColorInput") primaryColorInput!: ElementRef<HTMLInputElement>;
  @ViewChild("secondaryColorInput") secondaryColorInput!: ElementRef<HTMLInputElement>;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private usersService: UsersService,
    private loaderService: LoaderService,
    private scrollService: ScrollService,
    private actions$: Actions,
    private userLimitService: UserDataLimitsService,
  ) {
    super();
    this.form = this.fb.nonNullable.group({
      brainName: ["", [Validators.required, Validators.maxLength(256), Validators.minLength(1)]],
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddMyBrainContentComponent, {
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe();
  }

  emitScroll(event: Event): void {
    this.scrollService.emitScrollEvent(event);
  }

  ngOnInit(): void {
    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.brainId = brain.id;
        this.selectedBrain = brain || undefined;
        this.initialBrainSettings();
        this.form.controls.brainName.setValue(this.selectedBrain?.projectName || "");
        this.brainProjectName = this.selectedBrain?.projectName;
        this.isNameAutomatic = this.checkIsNameAutomatic(this.brainProjectName);
        if (this.selectedBrain?.bulkWebsiteCrawlJobStatus === CrawlWebSiteStatus.processing) {
          AppStore.isIndexing$.next(true);
          this.isProcessingBrainContents = true;
        }
      }
    });

    this.embeddingFiles$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        const embeddingFiles = data.filter((file) => file.projectId === this.selectedBrain?.id);
        this.processingBrainContents = embeddingFiles.filter((x) => x.status === EmbeddingFileStatus.Processing);
        this.isProcessingBrainContents = embeddingFiles.some(
          (content) => content.status === EmbeddingFileStatus.Processing,
        );
        this.webCount = embeddingFiles.filter(
          (a) => a.contentType === BrainContentType.Link || a.contentType === BrainContentType.Website,
        ).length;
        this.fileCount = embeddingFiles.filter((a) => a.contentType === BrainContentType.File).length;
        this.brainContents = embeddingFiles;
        this.cdr.markForCheck();
      }
    });

    this.user$.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
        this.cdr.markForCheck();
      }
    });

    this.route.firstChild?.params
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params["id"]),
        distinctUntilChanged(),
      )
      .subscribe((id) => {
        if (id && id !== this.brainId) {
          this.store.dispatch(getBrain({ id: id, isAuth: true }));
          this.store.dispatch(getEmbeddingFile({ id: id }));
          this.store.dispatch(getConversation({ id: id }));
        }
      });

    this.subs$.add(
      AppStore.sidebarActive$.subscribe((status) => {
        this.sidebarActive = status;
        this.cdr.detectChanges();
      }),
    );
  }

  initialBrainSettings() {
    this.settingsForm = this.fb.group({
      enableCustomStyling: [this.selectedBrain?.enableCustomStyling || null],
      settingShowSuggestions: [this.selectedBrain?.settingShowSuggestions || false],
      settingFollowUps: [this.selectedBrain?.settingFollowUps || false],
      settingShowReferences: [this.selectedBrain?.settingShowReferences || false],
      styleBackgroundPrimaryColor: [this.selectedBrain?.styleBackgroundPrimaryColor || "#554176"],
      styleBackgroundSecondaryColor: [this.selectedBrain?.styleBackgroundSecondaryColor || "#EFF1F6"],
    });

    this.settingsForm.valueChanges
      .pipe(
        debounceTime(400),
        tap((settings) => {
          const updatedBrain = { ...this.selectedBrain, ...settings };
          this.store.dispatch(updateBrain({ brain: updatedBrain }));
        }),
      )
      .subscribe();
    this.cdr.detectChanges();
  }

  back() {
    const currentUrl = this.router.url;
    if (currentUrl.includes("/brain")) {
      this.router.navigate([DASHBOARD_ROUTE]);
    } else if (currentUrl.includes("/content")) {
      this.router.navigate(["brain", this.brainId]);
    } else if (currentUrl.includes("/share-brain")) {
      this.router.navigate(["brain", this.brainId]);
    }
    this.cdr.markForCheck();
  }

  editBrainName() {
    this.displayBrainName = true;
    this.form.controls.brainName.setValue(this.brainProjectName || "");
    this.cdr.markForCheck();
  }

  cancelEditName() {
    this.displayBrainName = false;
    this.cdr.markForCheck();
  }

  updateBrainName() {
    this.brainProjectName = this.form.get("brainName")?.value;
    this.displayBrainName = false;
    this.cdr.markForCheck();
    if (this.selectedBrain && this.form.valid) {
      const brain = { ...this.selectedBrain, projectName: this.form.controls.brainName.value };
      this.store.dispatch(updateBrain({ brain: brain }));
    }
  }

  navigateToContent() {
    this.router.navigate([`${replaceParamsWithValue(CONTENT_ROUTE_ID, { id: this.brainId, tab: "waitlist" })}`]);
  }

  navigateToShare() {
    this.router.navigate([`${replaceParamsWithValue(SHARE_BRAIN_ROUTE_ID, { id: this.brainId })}`]);
  }

  backSave() {
    const dialogRef = this.dialog.open(BrainNameDialogComponent, {
      disableClose: true,
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.cdr.markForCheck();
      this.isNameAutomatic = false;
    });
  }

  backDelete() {
    const popupTranslations = {
      title: this.transloco.translate("leftSidebar.popupTitle"),
      message: this.transloco.translate("leftSidebar.popupMessage"),
      yesText: this.transloco.translate("leftSidebar.popupYes"),
      noText: this.transloco.translate("leftSidebar.popupNo"),
    };
    const params = {
      maxWidth: "420px",
      panelClass: "scrollable-dialog",
      height: "auto",
      disableClose: true,
      componentConfiguration: {
        title: popupTranslations.title,
        message: popupTranslations.message,
        yesText: popupTranslations.yesText,
        noText: popupTranslations.noText,
        showDeleteIcon: true,
      },
    };
    const matDialogConfigParams = Object.assign({}, params);
    const dialogRef = this.dialog.open(SimpleConfirmationPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;
    dialogRef.afterClosed().subscribe((resp) => {
      if (!resp) {
        this.store.dispatch(deleteBrain({ id: this.brainId, embeddingFilesIds: [] }));
        this.actions$.pipe(ofType(deleteBrainSuccess), take(1)).subscribe(() => {
          this.router.navigate(["dashboard"]);
          this.cdr.markForCheck();
        });
      } else if (resp === "closed") {
        dialogRef.close();
      } else {
        const dialogRef = this.dialog.open(BrainNameDialogComponent, {
          disableClose: true,
          panelClass: "scrollable-dialog",
        });
        dialogRef.afterClosed().subscribe(() => {
          this.isNameAutomatic = false;
          this.router.navigate(["dashboard"]);
        });
      }
    });
  }

  refreshData() {
    if (this.refreshDataInProcess) {
      return;
    }
    this.refreshDataInProcess = true;
    this.cdr.detectChanges();
    this.store.dispatch(getEmbeddingFile({ id: this.brainId }));
    setTimeout(() => {
      this.refreshDataInProcess = false;
      this.cdr.detectChanges();
    }, 650);
  }

  checkIsNameAutomatic(input: string | undefined): boolean {
    if (!input) {
      return true;
    }
    const pattern = /^Brain \d{1,2}:\d{1,2} \d{1,2}\.\d{1,2}\.\d{4}$/;
    return pattern.test(input);
  }

  closeSidebar() {
    AppStore.sidebarActive$.next(false);
  }

  triggerColorPicker(colorInput: HTMLInputElement): void {
    if (!colorInput.disabled) {
      colorInput.click();
    }
  }

  onBrainNameInputFocusIn() {
    if (isMobileInterfaceDefined()) {
      getMobileappLeftSidebarInterface().setKeyboardOverlay();
    }
  }

  onBrainNameInputFocusOut() {
    if (isMobileInterfaceDefined()) {
      getMobileappLeftSidebarInterface().setKeyboardDefault();
    }
  }

  isTruncated(element: HTMLElement): boolean {
    if (!element) {
      return false;
    }
    return element.scrollWidth > element.offsetWidth;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected readonly getCurrentYear = getCurrentYear;
  protected readonly MyBrainPlans = MyBrainPlans;
  protected readonly isRegularUser = isRegularUser;
  protected readonly redirectToAffiliates = redirectToAffiliates;
}
