import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ConversationState } from "app/store/reducers/conversation.reducer";

export const selectConversationState = createFeatureSelector<ConversationState>("conversationState");

export const selectConversation = createSelector(
  selectConversationState,
  (state: ConversationState) => state.conversation,
);

export const selectStreamedText = createSelector(
  selectConversationState,
  (state: ConversationState) => state.streamedText,
);
