import { ActionReducer, MetaReducer } from "@ngrx/store";

export function loadState(): any {
  try {
    const serializedState = localStorage.getItem("appState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function saveState(state: any): void {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("appState", serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
}

export function localStorageSyncMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    const nextState = reducer(state, action);

    saveState(nextState);

    return nextState;
  };
}

export const metaReducers: MetaReducer<any>[] = [localStorageSyncMetaReducer];
