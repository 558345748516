/* eslint-disable max-lines */
import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, NO_ERRORS_SCHEMA, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { Title } from "@angular/platform-browser";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile } from "app/app.model";
import {
  CONTENT_ROUTE_ID,
  DASHBOARD_ROUTE,
  IS_LIMIT_VISIBLE,
  MY_BRAIN_ROUTE,
  ProcessingEmbeddings,
  UsedEmbeddings,
} from "app/const/app-constant";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { UserLimitComponent } from "app/core/components/user-limit/user-limit.component";
import { getFormattedBrainName, replaceParamsWithValue } from "app/core/functions/helper-functions";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { UpdateTitle } from "app/services/update-title.service";
import { UsersService } from "app/services/users.service";
import { mergeMap, of, takeUntil } from "rxjs";
import { ConfirmDialogComponent, ConfirmDialogOption } from "../confirm-dialog/confirm-dialog.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { WebPageUploadComponent } from "../web-page-upload/web-page-upload.component";
import { AddMyBrainContentService } from "./add-my-brain-content.service";
import { ProjectTypes } from "app/enums/project-types.enum";
import { deleteBrain, setBrain, updateBrain } from "app/store/actions/brain.action";
import { deleteEmbeddingFiles, updateNewlyAddedStatus } from "app/store/actions/embedding-file.action";
import { UserDataLimitsService } from "app/services/user-data-limits.service";

@Component({
  selector: "app-add-my-brain-content",
  standalone: true,
  imports: [
    MatDialogModule,
    WebPageUploadComponent,
    TranslocoModule,
    FormsModule,
    FileUploadComponent,
    MatButtonModule,
    ConfirmDialogComponent,
    MatIconModule,
    UserLimitComponent,
    MatExpansionModule,
    NgIf,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: "./add-my-brain-content.component.html",
  styleUrls: ["./add-my-brain-content.component.scss"],
  providers: [AddMyBrainContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddMyBrainContentComponent extends BaseHttpComponent implements OnInit {
  @ViewChild(WebPageUploadComponent) webPageUploadComponent!: WebPageUploadComponent;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;

  contents: EmbeddingFile[] = [];
  selectedBrain!: Brain | null;
  isUploading = false;
  deleteInProgress = false;
  isConversationPage = false;
  isManagedContentPage = false;
  userDataLimits!: UserDataLimits;
  isLimitVisible!: boolean;
  isIndexing = false;
  originalTitle = "";
  userData?: UserData;
  webCount = 0;
  fileCount = 0;
  isNewlyAdded = false;
  newlyAddedFiles: EmbeddingFile[] = [];
  set isHidden(value: boolean) {
    if (this.isLimitVisible !== value) {
      this.isLimitVisible = value;
      const expiration = Date.now() + 24 * 60 * 60 * 1000;
      localStorage.setItem(IS_LIMIT_VISIBLE, JSON.stringify({ value, expiration }));
    }
  }

  get isHidden(): boolean {
    return this.isLimitVisible;
  }

  constructor(
    private addContentService: AddMyBrainContentService,
    private dialogRef: MatDialogRef<AddMyBrainContentComponent>,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private userService: UsersService,
    private userLimitService: UserDataLimitsService,
    private titleService: UpdateTitle,
    private title: Title,
  ) {
    super();
  }

  isUploadingEvent(event: boolean) {
    this.isUploading = event;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.originalTitle = this.title.getTitle();
    this.titleService.translatePageTitle("addContent");
    this.deleteInProgress = false;

    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.selectedBrain = brain;
      }
    });

    this.embeddingFiles$.pipe(takeUntil(this.destroy$)).subscribe((files) => {
      if (files) {
        this.contents = files.filter((files) => files.projectId === this.selectedBrain?.id);
        this.isNewlyAdded = this.contents.some((item) => item.newlyAdded === true);
        this.newlyAddedFiles = this.contents.filter((files) => files.newlyAdded === true);
        this.webCount = this.contents.filter(
          (a) => a.contentType === BrainContentType.Link || a.contentType === BrainContentType.Website,
        ).length;
        this.fileCount = this.contents.filter((a) => a.contentType === BrainContentType.File).length;
        this.setBrainName(this.contents[0]);
      }
    });

    this.user$.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
        this.isLimitVisible = this.userDataLimits.isContentExceeded || this.userDataLimits.isQuestionExceeded;
        this.isHidden = this.isLimitVisible;
      }
    });

    if (this.router.url.includes("/dashboard")) {
      if (this.userData) {
        this.addContentService.createBrain(this.userData, ProjectTypes.ContentBrain).subscribe((brain) => {
          if (brain) this.store.dispatch(setBrain({ brain: brain }));
        });
      }
    } else {
      if (this.router.url.includes("/content")) {
        if (
          this.router.url.substring(1) ===
            replaceParamsWithValue(CONTENT_ROUTE_ID, { id: (this.selectedBrain as Brain)?.id, tab: UsedEmbeddings }) ||
          this.router.url.substring(1) ===
            replaceParamsWithValue(CONTENT_ROUTE_ID, {
              id: (this.selectedBrain as Brain)?.id,
              tab: ProcessingEmbeddings,
            })
        ) {
          this.isConversationPage = false;
          this.isManagedContentPage = true;
        }
      }

      if (
        this.router.url.substring(1) ===
        replaceParamsWithValue(MY_BRAIN_ROUTE, { id: (this.selectedBrain as Brain)?.id })
      ) {
        this.isConversationPage = true;
        this.isManagedContentPage = false;
      }
      this.cdr.markForCheck();
    }

    AppStore.isIndexing$.subscribe((isIndexing) => {
      this.isIndexing = isIndexing;
    });
  }

  updateIsHidden(newValue: boolean) {
    const expiration = Date.now() + 24 * 60 * 60 * 1000;
    localStorage.setItem(IS_LIMIT_VISIBLE, JSON.stringify({ value: newValue, expiration }));
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any): void {
    const embeddingFilesIds = this.contents.map((x) => (x && x.id ? x.id : "")).filter((x) => x);
    if (embeddingFilesIds && this.router.url.includes(DASHBOARD_ROUTE)) {
      $event.preventDefault();
      this.deleteContent();
    } else {
      $event.preventDefault();
      this.deleteContent();
    }
  }

  addContent() {
    this.dialogRef.close();
    AppStore.zipUploadProgress$.next(null);
    this.store.dispatch(updateNewlyAddedStatus({ brainId: this.selectedBrain?.id as string }));
    if (this.router.url.includes("/content")) {
      return;
    } else {
      AppStore.isRedirectedFromMain$.next(true);
      this.router.navigate([replaceParamsWithValue(MY_BRAIN_ROUTE, { id: this.selectedBrain?.id as string })]);
      if (this.router.url.includes("/brain")) {
        if (this.selectedBrain) {
          this.store.dispatch(setBrain({ brain: this.selectedBrain }));
        }
        return;
      }
    }
  }

  closeDialog() {
    if (this.deleteInProgress) return;

    this.deleteInProgress = true;
    this.cdr.markForCheck();

    const shouldDeleteWithConfirmation = this.isNewlyAdded && this.router.url.includes(DASHBOARD_ROUTE);
    const shouldDeleteImmediately =
      (this.isNewlyAdded && !this.router.url.includes(DASHBOARD_ROUTE)) ||
      (!this.isNewlyAdded && this.router.url.includes(DASHBOARD_ROUTE));

    if (shouldDeleteWithConfirmation) {
      this.openConfirmationDialog();
      return;
    }

    if (shouldDeleteImmediately) {
      this.deleteContentAndCloseDialog();
      return;
    }

    if (!this.isNewlyAdded && (this.isManagedContentPage || this.isConversationPage)) {
      this.dialogRef.close();
    }
  }

  private openConfirmationDialog() {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "scrollable-dialog",
    });
    ref.componentInstance.title = this.transloco.translate("leftSidebar.deleteLabel");
    ref.componentInstance.confirmBodyTxt = this.translocoService.translate("leftSidebar.confirmBodyTxt");
    ref.componentInstance.okButtonTxt = this.translocoService.translate("leftSidebar.yes");
    ref.componentInstance.cancelButtonTxt = this.translocoService.translate("leftSidebar.cancel");

    ref
      .afterClosed()
      .pipe(
        mergeMap((result) => {
          this.deleteInProgress = false;
          if (result === ConfirmDialogOption.ok) {
            this.deleteContentAndCloseDialog();
          } else {
            this.dialogRef.close();
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  private deleteContentAndCloseDialog() {
    this.deleteContent();
    this.dialogRef.close();
  }

  deleteContent() {
    if (!this.router.url.includes(DASHBOARD_ROUTE)) {
      this.store.dispatch(
        deleteEmbeddingFiles({ brainId: this.selectedBrain?.id as string, content: this.newlyAddedFiles }),
      );
    } else {
      const embeddingFilesIds = this.contents.map((x) => (x && x.id ? x.id : "")).filter((x) => x);
      if (this.selectedBrain) {
        this.store.dispatch(deleteBrain({ id: this.selectedBrain?.id as string, embeddingFilesIds }));
      }
    }
  }

  setBrainName(firstFile: EmbeddingFile) {
    const newBrainName = getFormattedBrainName(
      firstFile?.metadata?.title || firstFile?.fileName || firstFile?.webpage || "",
    );

    // Skip if no brain is selected or name is already updated
    if (!this.selectedBrain || !newBrainName || this.selectedBrain?.projectName) {
      return;
    }

    const brain = { ...this.selectedBrain, projectName: newBrainName };
    this.store.dispatch(updateBrain({ brain: brain }));
  }

  shouldCloseDialog() {
    this.dialogRef.close();
  }

  areAllFilesInErrorState() {
    return this.contents.every((x) => x.status === 2);
  }

  override ngOnDestroy(): void {
    this.title.setTitle(this.originalTitle);
    this.store.dispatch(updateNewlyAddedStatus({ brainId: this.selectedBrain?.id as string }));
  }
}
