<div class="flex w-full flex-col md:flex-row h-full sticky top-0 left-0 z-10">
  <div
    [class]="sidebarActive ? 'translate-x-0' : '-translate-x-full'"
    class="top-0 left-0 left-sidebar md:static fixed md:z-0 z-[999] md:w-[330px] w-full ease-in-out duration-300 flex bottom-0 h-full"
    *transloco="let t; read: 'leftSidebar'"
  >
    <div
      *ngIf="settingsForm"
      class="flex justify-end h-[72px] md:hidden"
      [ngStyle]="{
        'background-color': settingsForm.get('enableCustomStyling')?.value
          ? settingsForm.get('styleBackgroundPrimaryColor')?.value
          : '#001E60'
      }"
    >
      <button class="text-white" (click)="closeSidebar()" class="p-4">
        <img src="./assets/images/icons/close.png" alt="Menu" />
      </button>
    </div>
    <div class="px-4 py-5 sidebar-scroll" *ngIf="selectedBrain && brainContents">
      <a
        class="back-btn text-primary font-semibold mb-5 inline-block cursor-pointer"
        (click)="back()"
        *ngIf="!isNameAutomatic || isContentPage; else newBrainButtons"
        ><img class="inline-block" src="./assets/images/icons/arrow-back.png" /> {{ t("back") }}</a
      >
      <ng-template #newBrainButtons>
        <div class="flex flex-row items-center my-5 gap-2">
          <button mat-button (click)="backDelete()">
            <img src="./assets/images/icons/arrow_back_24px.svg" />
            <span class="font-semibold cursor-pointer text-[#554176]">{{ t("back") }}</span>
          </button>
          <br />
          <button mat-button (click)="backSave()" class="!bg-[#554176]">
            <span class="font-semibold cursor-pointer text-white">{{ t("save") }}</span>
          </button>
        </div>
      </ng-template>
      <form [formGroup]="form" *ngIf="!isNameAutomatic">
        <h3 class="mt-6 relative rounded-lg pl-4 hover:bg-secondary-dark2 min-h-[52px] flex items-center !mb-8">
          <div class="brain-name text-base font-500" *ngIf="!displayBrainName">
            <div class="truncate max-w-[232px]">
              {{ brainProjectName }}
            </div>
            <span class="cursor-pointer absolute top-[14px] right-4 w-6 h-6" (click)="editBrainName()">
              <img class="inline-block align-top ml-1 w-6 h-6" src="./assets/images/icons/edit-icon.svg" />
            </span>
          </div>

          <div class="full absolute top-0 left-0 w-full h-[84px]" *ngIf="displayBrainName">
            <mat-form-field appearance="outline" class="w-full edit-input">
              <input
                id="brainNameInput"
                type="text"
                matInput
                formControlName="brainName"
                (keydown.enter)="updateBrainName()"
                (focus)="onBrainNameInputFocusIn()"
                (focusout)="onBrainNameInputFocusOut()"
              />
            </mat-form-field>
            <div class="w-15 absolute right-1 h-6">
              <button matSuffix mat-button class="btn--link btn--sm w-6 h-6 !min-w-[50px]" (click)="cancelEditName()">
                <img class="" src="./assets/images/icons/close-blue.svg" class="w-6 h-6" />
              </button>
              <button matSuffix mat-button class="btn--link btn--sm w-6 h-6 !min-w-[50px]" (click)="updateBrainName()">
                <img class="" src="./assets/images/icons/check-edit.svg" class="w-6 h-6" />
              </button>
            </div>
            <app-validation-message
              [formControlVal]="form.controls.brainName"
              [labelText]="t('brainNameLabel')"
            ></app-validation-message>
          </div>
        </h3>
      </form>
      <div
        class="box-card px-4 py-4 mb-4 rounded-md flex flex-col"
        [ngClass]="isProcessingBrainContents ? 'light-yellow' : 'light-blue'"
      >
        <h4 class="text-black-dark1 col-span-2" *ngIf="brainContents.length <= 0">{{ t("addBrainContents") }}</h4>
        <h4 class="text-black-dark1 col-span-2" *ngIf="brainContents.length > 0">{{ t("brainContents") }}</h4>
        <span class="text-xs text-black-lighter" *ngIf="userData">{{
          t("allowance", { x: userData.contentFileCount, y: userData.contentFileLimit })
        }}</span>
        <div class="flex gap-2 w-full pt-3" *ngIf="brainContents.length >= 0">
          <div class="flex-1 bg-white relative rounded-lg p-2 w-1/2" style="display: flex">
            <!-- Child 1 -->
            <div class="flex flex-col justify-center">
              <div class="flex items-center">
                <div class="bg-[#8E78B51F] bg-opacity-[12%] mr-2 rounded-full p-1.5 min-w-fit min-h-fit">
                  <img src="./assets/images/icons/brain-content/globe.svg" class="w-6 h-6" alt="" />
                </div>
                <div class="gap-1 flex flex-row items-center overflow-hidden">
                  <span class="text-base text-black font-semibold cursor-default">{{ webCount }}</span>
                  <span
                    class="text-sm text-black-lighter truncate"
                    #webText
                    [matTooltip]="isTruncated(webText) ? t(webCount <= 1 ? 'page' : 'pages') : ''"
                    [ngClass]="isTruncated(webText) ? 'cursor-pointer' : 'cursor-default'"
                    matTooltipPosition="above"
                    >{{ t(webCount <= 1 ? "page" : "pages") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 bg-white relative rounded-lg p-2 w-1/2" style="display: flex">
            <!-- Child 2 -->
            <div class="flex flex-col justify-center">
              <div class="flex items-center">
                <div class="bg-[#8E78B51F] bg-opacity-[12%] mr-2 rounded-full p-1.5 min-w-fit min-h-fit">
                  <img src="./assets/images/icons/brain-content/insert-file.svg" class="w-6 h-6" alt="" />
                </div>
                <div class="gap-1 flex flex-row items-center overflow-hidden">
                  <span class="text-base text-black font-semibold cursor-default">{{ fileCount }}</span>
                  <span
                    class="text-sm text-black-lighter truncate"
                    #fileText
                    [matTooltip]="isTruncated(fileText) ? t(fileCount <= 1 ? 'file' : 'files') : ''"
                    [ngClass]="isTruncated(fileText) ? 'cursor-pointer' : 'cursor-default'"
                    matTooltipPosition="above"
                  >
                    {{ t(fileCount <= 1 ? "file" : "files") }}
                  </span>
                </div>
              </div>
            </div>
            <button
              mat-button
              *ngIf="isProcessingBrainContents"
              (click)="refreshData()"
              class="!absolute text-secondary !w-6 !h-6 -right-3 -top-3 !p-0 !m-0 !min-w-min !rounded-full"
            >
              <mat-icon class="!m-0 !text-2xl !leading-6 !w-6 !h-6" [class.rotate]="refreshDataInProcess">refresh</mat-icon>
            </button>
          </div>
        </div>

        <div
          *ngIf="isProcessingBrainContents"
          class="min-w-[30px] bg-[#8e78b529] text-[#554176] py-1.5 px-3 font-semibold flex flex-row items-center rounded-[32px] gap-1 mt-3"
        >
          <mat-spinner [diameter]="15"></mat-spinner>
          <span class="text-sm">{{ "uploadedContentTranslation.indexing" | transloco }}</span>
        </div>

        <div class="flex flex-col pt-3" *ngIf="brainContents.length > 0 && !isContentPage">
          <a
            (click)="navigateToContent()"
            class="primary-light font-500 rounded-md flex gap-3 py-3 px-3 capitalize cursor-pointer w-full justify-center"
          >
            <span class="primary">{{ t("manageContent") }}</span>
            <img class="mr-2 inline-block align-top" src="./assets/images/icons/arrow-forward.svg" alt="" />
          </a>
        </div>
        <div class="flex w-full pt-3">
          <a
            (click)="openDialog()"
            class="primary-light font-500 rounded-md flex items-center py-3 px-3 capitalize cursor-pointer w-full"
            ><span class="grow primary-light-text"
              ><img class="mr-2 inline-block align-top" src="./assets/images/icons/pin-icon.png" alt="" />
              {{ t("addFileURL") }}</span
            >
            <img src="./assets/images/icons/add-icon.png" alt=""
          /></a>
        </div>
      </div>
      <mat-expansion-panel
        (opened)="panelSettingsState = true"
        (closed)="panelSettingsState = false"
        class="light-blue !mb-4 !shadow-none !rounded-md"
        *ngIf="!isRegularUser(this.userData)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img class="mr-2 inline-block align-top" src="./assets/images/my-brain/settings_24px.svg" alt="" />
            {{ t("settings") }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="primary-light rounded-md font-500 flex items-center py-3 px-3 mb-3 cursor-pointer mx-3">
          <span class="grow text-[#11255F] font-semibold" (click)="navigateToShare()"
            ><img class="mr-2 inline-block align-top" src="./assets/images/icons/share-icon.png" alt="" />
            {{ t("share") }}</span
          >
          <img src="./assets/images/icons/forward-icon.png" alt=""
        /></a>
      </mat-expansion-panel>
      <mat-expansion-panel
        (opened)="panelConfigState = true"
        (closed)="panelConfigState = false"
        class="light-blue !shadow-none !rounded-md"
        *ngIf="!isRegularUser(this.userData)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img class="mr-2 inline-block align-top" src="./assets/images/my-brain/tune_24px.svg" alt="" />
            {{ t("customiseBrain") }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="settingsForm">
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("branding") }}</span>
            <div class="flex flex-col justify-center items-center">
              <mat-slide-toggle formControlName="enableCustomStyling"></mat-slide-toggle>
              <span>{{ settingsForm.get("enableCustomStyling")?.value ? t("enabled") : t("disabled") }}</span>
            </div>
          </div>
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("primary") }}</span>
            <div
              class="flex flex-row justify-between items-center bg-white border border-[#e5e7eb] p-1.5 pr-2.5 rounded gap-1 w-[120px] cursor-pointer"
              [ngClass]="{ 'cursor-not-allowed': !settingsForm.get('enableCustomStyling')?.value }"
              (click)="
                settingsForm.get('enableCustomStyling')?.value
                  ? triggerColorPicker(primaryColorInput)
                  : $event.preventDefault()
              "
            >
              <input
                type="color"
                #primaryColorInput
                formControlName="styleBackgroundPrimaryColor"
                class="color-input"
                [ngClass]="{ 'cursor-not-allowed': !settingsForm.get('enableCustomStyling')?.value }"
              />
              <span>{{ settingsForm.get("styleBackgroundPrimaryColor")?.value }}</span>
              <span class="icon"></span>
            </div>
          </div>
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("secondary") }}</span>
            <div
              class="flex flex-row justify-between items-center bg-white border border-[#e5e7eb] p-1.5 pr-2.5 rounded gap-2 w-[120px] cursor-pointer"
              [ngClass]="{ 'cursor-not-allowed': !settingsForm.get('enableCustomStyling')?.value }"
              (click)="
                settingsForm.get('enableCustomStyling')?.value
                  ? triggerColorPicker(secondaryColorInput)
                  : $event.preventDefault()
              "
            >
              <input
                type="color"
                #secondaryColorInput
                formControlName="styleBackgroundSecondaryColor"
                class="color-input"
                [ngClass]="{ 'cursor-not-allowed': !settingsForm.get('enableCustomStyling')?.value }"
              />
              <span>{{ settingsForm.get("styleBackgroundSecondaryColor")?.value }}</span>
              <span class="icon"></span>
            </div>
          </div>
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("showExampleQ") }}</span>
            <div class="flex flex-col justify-center items-center">
              <mat-slide-toggle formControlName="settingShowSuggestions"></mat-slide-toggle>
              <span>{{ this.selectedBrain!.settingShowSuggestions ? t("enabled") : t("disabled") }}</span>
            </div>
          </div>
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("showFollowUpQ") }}</span>
            <div class="flex flex-col justify-center items-center">
              <mat-slide-toggle formControlName="settingFollowUps"></mat-slide-toggle>
              <span>{{ this.selectedBrain!.settingFollowUps ? t("enabled") : t("disabled") }}</span>
            </div>
          </div>
          <div class="flex flex-row justify-between p-4 items-center">
            <span>{{ t("showAnswerRef") }}</span>
            <div class="flex flex-col justify-center items-center">
              <mat-slide-toggle formControlName="settingShowReferences"></mat-slide-toggle>
              <span>{{ this.selectedBrain!.settingShowReferences ? t("enabled") : t("disabled") }}</span>
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </div>
    <div class="px-4 py-4 mb-4 gap-2 flex justify-center items-center">
      <button (click)="redirectToAffiliates()">
        <div class="flex text-f13 items-center py-1 rounded-lg">
          <i class="mr-2">
            <img src="./assets/images/refer_a_friend.svg" alt="" class="w-[18px] h-[18px]" />
          </i>
          <span class="text-secondary font-semibold">{{ "referAFriend" | transloco }}</span>
        </div>
      </button>
    </div>
    <div class="bg-white-smoke min-h-[68px] py-8 flex items-center border border-neutral-border">
      <div class="container m-auto">
        <a class="text-black-lighter text-sm" href="https://rezolve.com" target="_blank"
          >&copy; {{ getCurrentYear() }} Rezolve AI Ltd.</a
        >
      </div>
    </div>
  </div>
  <div
    class="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
    (scroll)="emitScroll($event)"
  >
    <router-outlet></router-outlet>
  </div>
</div>
