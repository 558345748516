<div class="flex flex-col gap-[10px] items-center data-container">
  <div class="mb-10 self-center flex flex-row justify-between items-center w-full" *ngIf="configuration.showDeleteIcon">
    <span class="text-xl font-medium leading-6">{{ configuration.title }}</span>
    <button (click)="onHide()" mat-icon-button class="rate-icons" tabindex="-1">
      <mat-icon color="primary">close</mat-icon>
    </button>
  </div>

  <span *ngIf="configuration.message" class="text-base font-normal leading-6 w-full text-[#191919C2] break-all"
    >{{ configuration.message }}
  </span>
  <ng-container *ngIf="configuration.messageList">
    <span *ngFor="let message of configuration.messageList" [ngClass]="message.shouldWrap ? 'wrap' : 'message'"
      >{{ message.message }}
    </span>
  </ng-container>

  <div mat-dialog-actions class="flex flex-col sm:flex-row sm:!justify-end gap-2.5 sm:gap-0 w-full min-w-[220px]">
    <button mat-button *ngIf="!configuration.disableNoButton" (click)="onCancel()" tabindex="-1">
      <span class="font-semibold cursor-pointer text-[#554176]">{{
        configuration.noText ? configuration.noText : "NO"
      }}</span>
    </button>
    <button mat-stroked-button [mat-dialog-close]="true" class="!border-accent" tabindex="-1">
      <span class="font-semibold cursor-pointer text-[#554176]">{{
        configuration.yesText ? configuration.yesText : "YES"
      }}</span>
    </button>
  </div>
</div>
