import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, FormControl, FormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslocoDirective, TranslocoService } from "@ngneat/transloco";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { takeUntil } from "rxjs";
import { updateBrain } from "app/store/actions/brain.action";

@Component({
  selector: "app-brain-name-dialog",
  standalone: true,
  // eslint-disable-next-line max-len
  imports: [
    CommonModule,
    TranslocoDirective,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ValidationMessageModule,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: "./brain-name-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrainNameDialogComponent extends BaseHttpComponent {
  brainDetailForm;
  overriteMessageError;
  brain?: Brain | null;
  constructor(
    private fb: FormBuilder,
    private translocoService: TranslocoService,
  ) {
    super();
    this.brainDetailForm = this.fb.group({
      brainName: new FormControl("", [Validators.required, Validators.maxLength(256), Validators.minLength(1)]),
    });
    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.brain = brain;
      }
    });

    this.overriteMessageError = { required: this.translocoService.translate("addBrainName.errorMessage") };
  }

  onClick() {
    if (this.brainDetailForm.valid && this.brain) {
      const brainName = this.brainDetailForm.get("brainName")?.value;

      if (brainName) {
        const brain = { ...this.brain, projectName: brainName };
        this.store.dispatch(updateBrain({ brain: brain }));
      }
    }
  }
}
