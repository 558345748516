import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { UsersService } from "app/services/users.service";
import { getUser, setUser, updateUser } from "app/store/actions/user.actions";
import { catchError, of } from "rxjs";
import { UserData } from "app/pages/home/home.model";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UsersService,
  ) {}

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUser),
      mergeMap(() =>
        this.userService.get().pipe(
          map(
            (user) => {
              const userData = user.data;
              return setUser({ user: userData as UserData });
            },
            catchError((error) => {
              console.error("Error loading brain:", error);
              return of();
            }),
          ),
        ),
      ),
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUser),
      mergeMap((action) =>
        this.userService.update(action.user).pipe(
          map((user) => {
            const userData = user.data;
            return setUser({ user: userData as UserData });
          }),
          catchError((error) => {
            console.error("Error loading brain:", error);
            return of();
          }),
        ),
      ),
    );
  });
}
