import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    if (!value || value.length <= maxLength) {
      return value; // No truncation needed
    }

    const dotIndex = value.lastIndexOf(".");
    if (dotIndex === -1) {
      // No extension found, just truncate the string normally
      return value.substring(0, maxLength - 3) + "...";
    }

    const extension = value.substring(dotIndex);
    const baseName = value.substring(0, dotIndex);

    const reservedLength = extension.length + 3;
    if (maxLength <= reservedLength) {
      return value;
    }

    const visibleBaseNameLength = maxLength - reservedLength;
    const startPart = baseName.substring(0, visibleBaseNameLength - 3);
    const endPart = baseName.substring(baseName.length - 8);

    return `${startPart}...${endPart}${extension}`.trim();
  }
}
