import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as BrainActions from "app/store/actions/brain.action";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { clearBrain, deleteBrainSuccess } from "app/store/actions/brain.action";
import { clearEmbeddingFileState } from "app/store/actions/embedding-file.action";
import { getUser } from "app/store/actions/user.actions";

@Injectable()
export class BrainEffects {
  constructor(
    private actions$: Actions,
    private embeddingProjectService: EmbeddingProjectsService,
  ) {}

  loadBrain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrainActions.getBrain),
      mergeMap((action) =>
        this.embeddingProjectService.get(action.id, action.isAuth).pipe(
          map((response) => {
            const brain = response.data;
            return BrainActions.setBrain({ brain: brain as Brain });
          }),
          catchError((error) => {
            console.error("Error loading brain:", error);
            return of();
          }),
        ),
      ),
    ),
  );

  updateBrain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrainActions.updateBrain),
      mergeMap((action) =>
        this.embeddingProjectService.update(action.brain).pipe(
          map((response) => {
            const brain = response.data;
            return BrainActions.setBrain({ brain: brain as Brain });
          }),
          catchError((error) => {
            console.error("Error updating brain:", error);
            return of();
          }),
        ),
      ),
    ),
  );

  deleteBrain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrainActions.deleteBrain),
      mergeMap((action) =>
        this.embeddingProjectService.delete(action.id, action.embeddingFilesIds).pipe(
          mergeMap((res) => {
            if (res && res.isSuccess) {
              return [deleteBrainSuccess(), clearBrain(), clearEmbeddingFileState(), getUser()];
            }
            throw new Error("Delete failed");
          }),
          catchError((error) => of(BrainActions.deleteBrainFailure({ error }))),
        ),
      ),
    ),
  );
}
