import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConversationReference } from "app/api-models/conversation.models";
import { TranslocoDirective } from "@ngneat/transloco";
import { PipesModule } from "app/pipes/pipes.module";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { catchError, of, tap } from "rxjs";
import { BaseComponent } from "app/core/components/base-component/base-component.component";
import { HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ErrorModel } from "app/app.model";

@Component({
  selector: "app-conversation-reference",
  standalone: true,
  imports: [CommonModule, TranslocoDirective, PipesModule],
  templateUrl: "./conversation-reference.component.html",
  styleUrls: ["./conversation-reference.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationReferenceComponent extends BaseComponent {
  constructor(private embeddingFileService: EmbeddingFileService) {
    super();
  }

  @Input() stringReferences: string[] = [];
  @Input() detailedReferences?: ConversationReference[];
  openFileDirectly(reference: ConversationReference) {
    const fileName = reference.link.substring(reference.link.lastIndexOf("/") + 1, reference.link.indexOf("?"));
    return this.embeddingFileService
      .downloadFile(fileName)
      .pipe(
        map((response) => {
          if ((response as any).body instanceof Blob) {
            const url = window.URL.createObjectURL((response as any).body);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            this.successMsg(this.transloco.translate("downloadReference.successfulDownload"));
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof Blob) {
            let errorModel: ErrorModel;
            const reader = new FileReader();
            reader.onload = () => {
              const errorText = reader.result as string;
              try {
                const errorJson = JSON.parse(errorText);
                errorModel = new ErrorModel(error.status, errorJson.message, errorJson.details || null);
                this.errorMsg(this.transloco.translate(`downloadReference.${errorModel.message}`));

              } catch (e) {
                // If errorText is not JSON, just return the raw text as the message
                errorModel = new ErrorModel(error.status, "failedToDownload", errorText);
                this.errorMsg(this.transloco.translate(`downloadReference.${errorModel.message}`));
              }
            };
            reader.readAsText(error.error);
          }
          return of(false);
        }),
      )
      .subscribe();
  }
}
