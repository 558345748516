import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "app/environments/environment";
import { GoogleAnalyticsService } from "app/google-analytics.service";
import { ChatBotService } from "app/services/chat-bot.service";
import { of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { LaunchDarklyService } from './services/launchdarkly.service';
import { UsersService } from "./services/users.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private scriptInjected = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.handleChatBot();
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private chatBotService: ChatBotService,
    private router: Router,
    private renderer: Renderer2,
    private userService: UsersService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, "loaded");

    this.checkForChatBotScript();

    if(environment.environmentName === "development"){
      this.userService.get().pipe(
        mergeMap((response) => {
          if (response?.isSuccess && response?.data) {
            const context = {
              kind: 'user', 
              key: response.data.id,
              email: response.data.email,
            };
            this.launchDarklyService.initialize(environment.launchdarkly.clientSideID, context);
          }
          return of(null);
        })
      ).subscribe();
    }
  }

  private checkForChatBotScript(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.handleChatBot();
    });
  }

  private handleChatBot(): void {
    const currentRoute = this.router.url;

    if (currentRoute === "/" || currentRoute.startsWith("/dashboard")) {
      this.pageWidthCheck();
    } else {
      this.hideChatBot();
    }
  }

  private pageWidthCheck(): void {
    const windowWidth = window.innerWidth;

    if (windowWidth < 768) {
      this.hideChatBot();
    } else {
      this.injectChatScriptOnce();
    }
  }

  private injectChatScriptOnce(): void {
    if (!this.scriptInjected) {
      const scriptSrc = `${environment.baseUrl}/assets/scripts/embed-chat-js-min.js`;
      const dataId = environment.brainHelpId;
      this.chatBotService.showChatBot(scriptSrc, dataId);
      this.scriptInjected = true;
    }
  }

  private hideChatBot(): void {
    this.chatBotService.hideChatBot();
    this.scriptInjected = false;
  }
}
