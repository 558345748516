import { Action, createReducer, on } from "@ngrx/store";
import { Brain } from "app/pages/dashboard/dashboard.model";
import * as BrainActions from "app/store/actions/brain.action";

export interface BrainState {
  brain: Brain | null;
}

export const initialBrainState: BrainState = {
  brain: null,
};

const brainReducer = createReducer(
  initialBrainState,
  on(BrainActions.setBrain, (state, { brain }) => ({ ...state, brain })),
  on(BrainActions.clearBrain, (state) => ({ ...state, brain: null })),
);

export function reducer(state = initialBrainState, action: Action): BrainState {
  return brainReducer(state, action);
}
