import { Injectable } from "@angular/core";
import { Subject, throttleTime } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScrollService {
  private scrollPositionSubject = new Subject<boolean>();
  scrollPosition$ = this.scrollPositionSubject.asObservable();

  updateScrollPosition(atBottom: boolean) {
    this.scrollPositionSubject.next(atBottom);
  }

  private scrollEvent = new Subject<Event>();
  scrollEvent$ = this.scrollEvent.asObservable().pipe(throttleTime(100));

  emitScrollEvent(event: Event): void {
    this.scrollEvent.next(event);
  }
}
