import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatMap, delay, delayWhen, from, of, takeUntil, tap, timer } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as ConversationActions from "app/store/actions/conversation.action";
import { ConversationService } from "app/services/conversation.service";
import { Store } from "@ngrx/store";
import { AppStore } from "app/app-store.service";

@Injectable()
export class ConversationEffects {
  constructor(
    private actions$: Actions,
    private conversationService: ConversationService,
    private store: Store,
  ) {}

  private getDelayTime(charCount: number): number {
    if (charCount < 100) return 25;
    if (charCount < 250) return 20;
    if (charCount < 500) return 10;
    if (charCount <= 1000) return 5;
    return 0;
  }

  loadConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.getConversation),
      mergeMap(({ id }) =>
        this.conversationService.getBrainConversation(id).pipe(
          map((response) => {
            const conversation = response.data || [];
            return ConversationActions.setConversation({ conversation: conversation });
          }),
          catchError((error) => {
            console.error("Error loading conversation:", error);
            return of();
          }),
        ),
      ),
    ),
  );

  deleteConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.deleteConversations),
      mergeMap(({ conversationId, brainId }) =>
        this.conversationService.deleteBrainConversations(conversationId, brainId).pipe(
          map(() => ConversationActions.clearConversationState()),
          catchError((error) => {
            console.error("Error deleting conversation:", error);
            return of();
          }),
        ),
      ),
    ),
  );

  streamText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.startStream),
      concatMap(({ text, conversation }) => {
        let currentText = "";
        const cancelStream$ = this.actions$.pipe(ofType(ConversationActions.cancelStream));

        return from(text.split("")).pipe(
          concatMap((chunk) => {
            const delay = this.getDelayTime(text.length);
            return timer(delay).pipe(concatMap(() => of(chunk)));
          }),
          takeUntil(cancelStream$),
          tap((chunk) => {
            currentText += chunk;
            this.store.dispatch(ConversationActions.updateStream({ streamedText: currentText }));
          }),
          concatMap((_, index) =>
            index === text.length - 1
              ? of(ConversationActions.updateLastMessage({ conversation }), ConversationActions.cancelStream())
              : of(),
          ),
          tap({
            complete: () => {
              AppStore.chatApiInProgress$.next({ brainId: "", inProgress: false });
            },
          }),
        );
      }),
    ),
  );
}
