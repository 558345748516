<!-- eslint-disable max-len -->
<!-- eslint-disable max-lines -->
<div *transloco="let t; read: 'defaultFollowUpQuestion'" class="flex flex-col h-screen">
  <div class="flex flex-col h-screen flex-1">
    <app-header
      [showOnlyLanguage]="false"
      [customizeColors]="isCustomStylingEnabled"
      [customizeColorValues]="brainStyle"
      [isSharedBrain]="true"
      class="sticky top-0"
    ></app-header>
    <div *ngIf="!isLoading; else loading" class="flex-1 overflow-y-auto" #chatContainer (scroll)="onScroll($event)">
      <div *ngIf="brain; else noBrain" class="flex-1">
        <div class="flex-1">
          <div class="container pt-4 mx-auto flex-1">
            <ng-container *ngFor="let chat of parsedChats; index as i">
              <div class="flex flex-row-reverse mb-5" *ngIf="chat.role === role.user">
                <div class="msg bg-gray-light rounded-tr-none rounded-[12px] py-4 px-4 text-sm max-w-[95%]">
                  <div [innerHTML]="chat.text"></div>
                  <span class="text-xs text-light-black font-500 text-right block text uppercase">
                    {{ chat.creationDate | customDate }}
                  </span>
                </div>
              </div>
              <div class="flex mb-5" *ngIf="chat.role === role.assistant">
                <img
                  *ngIf="chat.text; else loadingIcon"
                  class="rounded-xl h-10 w-10 mr-3"
                  src="./assets/images/my-brain/brain-purple.png"
                  alt=""
                  width="25"
                  height="auto"
                />
                <ng-template #loadingIcon>
                  <img
                    class="rounded-xl h-10 w-10 mr-3"
                    src="./assets/images/my-brain/brain-purple-animated.gif"
                    alt=""
                    width="25"
                    height="auto"
                  />
                </ng-template>
                <div
                  class="rounded-tl-none rounded-[12px] py-4 px-4 text-sm max-w-[95%] chatContent"
                  *ngIf="chat.text; else loader"
                  [dir]="activeLang === 'ar_ar' ? 'rtl' : 'ltr'"
                  [ngStyle]="{
                    'background-color': isCustomStylingEnabled ? addAlpha(brainStyle?.SecondaryColor, 0.2) : '#eff1f6',
                    'border-color': isCustomStylingEnabled ? addAlpha(brainStyle?.SecondaryColor, 0.8) : 'none'
                  }"
                >
                  <div *ngFor="let chatChunk of chat.text | codeSplit">
                    <ng-container *ngIf="chatChunk.isCodeMatch; else plainTextContent">
                      <div class="bg-white-smoke" *ngIf="chatChunk.content | codeFormat as dataContent">
                        <div class="bg-white-smoke">
                          <div>
                            <div class="py-3">
                              <label class="rezolve-font-bold px-4 py-3">
                                {{ dataContent.languageName }}
                              </label>
                            </div>
                            <div class="px-4 bg-gray-light py-3">
                              <code class="bg-gray-light rezolve-dm-mono leading-loose whitespace-pre-wrap">
                                {{ dataContent.code }}
                              </code>
                            </div>
                          </div>
                          <div class="flex justify-between pt-3 py-3">
                            <div class="col px-3">
                              <label>{{ t("allCodeCaution") }}</label>
                            </div>
                            <div class="col px-3">
                              <button
                                mat-icon-button
                                class="rate-icons"
                                [matTooltip]="t('copyToClipboard')"
                                [matTooltipClass]="'copy-to-clipboard'"
                                (click)="copyToClipboard(dataContent.code)"
                              >
                                <img
                                  class="inline-block cursor-pointer absolute !w-4 !h-4"
                                  src="./assets/images/icons/copy-icon.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #plainTextContent>
                      <markdown class="markdown-container" [data]="chatChunk.content" />
                    </ng-template>
                  </div>
                  <div class="mt-2" *ngIf="(chat.references?.length || chat.detailedReferences?.length) && brain.settingShowReferences">
                    <div class="inline-block" (click)="chatAccordionOpen[i] = !chatAccordionOpen[i]">
                      <div class="text-sm font-semibold inline-block mr-2 text-black-lighter cursor-pointer mb-0">
                        {{ t("references") }}
                      </div>
                      <img
                        src="./assets/images/icons/arrow-down.png"
                        alt=""
                        class="inline-block ml-2 cursor-pointer grayscale"
                        *ngIf="!chatAccordionOpen[i]; else showArrow"
                      />
                      <ng-template #showArrow>
                        <img
                          src="./assets/images/icons/arrow-up.png"
                          alt=""
                          class="inline-block ml-2 cursor-pointer grayscale"
                        />
                      </ng-template>
                    </div>
                    <div class="flex flex-col" *ngIf="chatAccordionOpen[i]">
                      <app-conversation-reference
                        class="flex flex-row"
                        [stringReferences]="chat.references"
                        [detailedReferences]="chat.detailedReferences"
                      ></app-conversation-reference>
                      <div
                        class="w-full text-zinc-900 text-xs mt-2"
                        *ngIf="brain.projectType === ProjectTypes.InternetSearchBrain"
                      >
                        {{ "brainDisclaimer" | transloco }}
                      </div>
                    </div>
                  </div>

                  <span class="text-xs text-light-black font-500 block text-right uppercase">
                    <strong *ngIf="chat?.extraMetadata?.requestLogs && allowBrainLLMSelect" class="mr-2 text-black">
                      {{ chat.extraMetadata.requestLogs }}
                    </strong>
                    {{ chat.creationDate | customDate }}
                  </span>

                  <div class="flex border-t-2 light-blue-border mt-3 pt-3 gap-4">
                    <button
                      mat-icon-button
                      class="copy-icon"
                      [matTooltip]="t('copyToClipboard')"
                      [matTooltipClass]="'copy-icon-tooltip'"
                      (click)="copyToClipboard(chat.text)"
                    >
                      <img
                        class="inline-block cursor-pointer absolute !w-4 !h-4"
                        src="./assets/images/icons/copy-icon.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <ng-template #loader>
                  <div
                    [dir]="activeLang === 'ar_ar' ? 'rtl' : 'ltr'"
                    class="rounded-tl-none rounded-[12px] py-4 px-4 text-sm max-w-[95%] chatContent"
                    [ngStyle]="{
                      'background-color': isCustomStylingEnabled
                        ? addAlpha(brainStyle?.SecondaryColor, 0.2)
                        : '#eff1f6',
                      'border-color': isCustomStylingEnabled ? addAlpha(brainStyle?.SecondaryColor, 0.8) : 'none'
                    }"
                  >
                    <markdown class="chunk markdown-container is-loading" [data]="this.stream" />
                    <div *ngIf="this.stream === ''" class="text-[#191919] opacity-70 italic">
                      {{
                        (brain.projectType === ProjectTypes.ContentBrain
                          ? "brainSourcesDoingAIMagic"
                          : internetSearchStatus === InternetSearchStatus.searching
                          ? "searchInternet"
                          : "searchInternetDoingAIMagic"
                        ) | transloco
                      }}
                    </div>
                  </div>
                </ng-template>
              </div>

              <div
                *ngIf="i + 1 === (chats$ | async)?.length && chat.text && chat.role === role.assistant"
                id="last-chat"
                class="h12 ml-3 pb-3 pl-[40px]"
              >
                <ng-container *ngIf="chat?.extraMetadata?.requiresConfirmation; else followUpQuestions">
                  <div class="mt-4 p-3 bg-neutral-50 rounded-lg border-l-4">
                    <div class="grow shrink basis-0 text-zinc-900 text-opacity-75 text-base">
                      {{ t("confirmation") }}
                    </div>
                    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2">
                      <ng-container *ngFor="let option of ['yes', 'no']">
                        <app-follow-up-question
                          [followUpQuestion]="t(option)"
                          (questionAsked)="sendConfirmation(t(option))"
                        />
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-template #followUpQuestions>
                  <ng-container *ngIf="brain && followuppQuestions && !chat.isAssistantQuestion">
                    <div class="mt-4 p-3 bg-neutral-50 rounded-lg border-l-4">
                      <div class="grow shrink basis-0 text-zinc-900 text-opacity-75 text-base">
                        {{ t("description") }}
                      </div>
                      <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2">
                        <div *ngFor="let followUpQuestion of followuppQuestions; index as fIndex">
                          <app-follow-up-question
                            [followUpQuestion]="followUpQuestion"
                            [index]="fIndex"
                            (questionAsked)="askSuggestedQuestion($event)"
                            [customizeColors]="isCustomStylingEnabled"
                            [customizeColorValues]="brainStyle"
                          >
                          </app-follow-up-question>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>

                <button
                  *ngIf="brain"
                  (click)="deleteMessages()"
                  class="w-auto py-2.5 flex items-center justify-center px-4 !rounded-[8px] mt-4"
                  [ngStyle]="{
                    'background-color': isCustomStylingEnabled ? addAlpha(brainStyle?.PrimaryColor, 0.2) : '#2653d914'
                  }"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    [style.fill]="isCustomStylingEnabled ? brainStyle?.PrimaryColor : '#2653D9'"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2338 4.7625C12.1463 3.675 10.6538 3 8.99625 3C5.68125 3 3.00375 5.685 3.00375 9C3.00375 12.315 5.68125 15 8.99625 15C11.7938 15 14.1263 13.0875 14.7938 10.5H13.2338C12.6188 12.2475 10.9538 13.5 8.99625 13.5C6.51375 13.5 4.49625 11.4825 4.49625 9C4.49625 6.5175 6.51375 4.5 8.99625 4.5C10.2413 4.5 11.3513 5.0175 12.1613 5.835L9.74625 8.25H14.9963V3L13.2338 4.7625Z"
                    />
                  </svg>
                  <span
                    class="mx-2"
                    [ngStyle]="{ color: isCustomStylingEnabled ? brainStyle?.PrimaryColor : 'text-primary' }"
                    >{{ t("clearConversation") }}</span
                  >
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-template #noBrain>
        <app-no-brain [isEmbeddedChat]="true"></app-no-brain>
      </ng-template>
    </div>
    <ng-template #loading>
      <div class="flex flex-col h-full w-full items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
    <div class="sticky bottom-0" *ngIf="brain">
      <div class="flex flex-row-reverse sticky h-0" *ngIf="parsedChats.length && scrolledUp">
        <button
          mat-mini-fab
          (click)="scrollToBottom()"
          class="right-3 bottom-[calc(0.75rem+40px)] md:right-6 md:bottom-[calc(1.5rem+35px)] !bg-violet-semi"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
      <app-user-limit [isHidden]="isHidden" [isEmbeddedChat]="true" class="w-full"></app-user-limit>
      <div
        class="w-full border-t border-t-[#c8c8c8] bg-white"
        [ngClass]="brain.interactiveInternetSearch === 'active' ? 'pt-6' : 'py-6'"
      >
        <div class="container m-auto">
          <div class="max-w-[896px] mx-auto">
            <!-- search Row -->
            <div [dir]="activeLang === 'ar_ar' ? 'rtl' : 'ltr'">
              <app-ask-my-brain-search
                [customizeColors]="brain.enableCustomStyling"
                [customizeColorValues]="brainStyle"
                [conversationPage]="true"
                [activeLang]="activeLang"
                [embeddedChatHasContent]="brain.projectType === ProjectTypes.ContentBrain"
                [isEmbeddedChat]="true"
                [canAddContent]="false"
                [embeddedChatCreator]="embeddedChatCreator"
              ></app-ask-my-brain-search>
            </div>
            <div *ngIf="parsedChats?.length === 0 && brain">
              <div
                class="flex flex-col lg:flex-row mt-2 gap-4"
                *ngIf="parsedChats.length === 0 && brain.settingShowSuggestions"
              >
                <ng-container *ngFor="let suggestionQuestion of suggestedQuestions?.slice(0, 3)">
                  <button
                    class="bg-primary-card rounded-md flex items-center p-3 cursor-pointer lg:w-1/3 w-full"
                    [ngStyle]="{
                      'background-color': brain.enableCustomStyling
                        ? addAlpha(brain.styleBackgroundSecondaryColor, 0.2)
                        : '#2653D914'
                    }"
                    (click)="askSuggestedQuestion(suggestionQuestion)"
                    [disabled]="(AppStore.chatApiInProgress$ | async)?.inProgress || userDataLimits?.isQuestionExceeded"
                    [matTooltip]="isTextTruncated(suggestionSpan) ? suggestionQuestion : ''"
                    [matTooltipClass]="'suggested-questions'"
                  >
                    <span class="text-secondary-dark mr-2 min-w-[20px] h-5">
                      <img src="./assets/images/icons/search-bar/lightbulb-blue-icon.svg" alt="" class="w-5 h-5" />
                    </span>
                    <span
                      #suggestionSpan
                      class="text-left font-medium text-sm flex-grow-0 flex-shrink text-limit-3"
                      [innerHTML]="suggestionQuestion"
                    >
                    </span>
                    <img src="./assets/images/icons/forward-icon.png" alt="" class="flex-shrink-0 ml-2" />
                  </button>
                </ng-container>
              </div>
            </div>
            <p
              class="text-[10px] leading-[11px] text-rezolve-dark-gray !m-0 !my-2"
              [dir]="activeLang === 'ar_ar' ? 'rtl' : 'ltr'"
              *ngIf="brain.interactiveInternetSearch === 'active'"
            >
              {{ t("disclaimer") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
