import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { SimpleConfirmPopupConfiguration } from "app/core/components/simple-confirmation-popup/simple-confirmation-popup.configuration";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-simple-confirmation-popup",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule],
  templateUrl: "./simple-confirmation-popup.component.html",
  styleUrls: ["./simple-confirmation-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleConfirmationPopupComponent {
  @Input() configuration: SimpleConfirmPopupConfiguration = {};

  constructor(public matDialogRef: MatDialogRef<SimpleConfirmationPopupComponent>) {}

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  onHide(): void {
    this.matDialogRef.close("closed");
  }
}
