import { ActionReducerMap } from "@ngrx/store";
import { BrainState } from "app/store/reducers/brain.reducer";
import { EmbeddingFileState } from "app/store/reducers/embedding-file.reducer";
import { UserState } from "app/store/reducers/user.reducer";
import { ConversationState } from "app/store/reducers/conversation.reducer";
import * as Brain from "app/store/reducers/brain.reducer";
import * as Embedding from "app/store/reducers/embedding-file.reducer";
import * as User from "app/store/reducers/user.reducer";
import * as Conversation from "app/store/reducers/conversation.reducer";

export interface State {
  selectedBrainState: BrainState;
  embeddingFileState: EmbeddingFileState;
  userState: UserState;
  conversationState: ConversationState;
}

export const reducers: ActionReducerMap<State> = {
  selectedBrainState: Brain.reducer,
  embeddingFileState: Embedding.reducer,
  userState: User.reducer,
  conversationState: Conversation.reducer,
};
