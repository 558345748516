import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { Conversation } from "app/api-models/conversation.models";
import { AppStore } from "app/app-store.service";
import { AppService } from "app/app.service";
import { DASHBOARD_ROUTE, MY_BRAIN_ROUTE } from "app/const/app-constant";
import { BrainStyle } from "app/core/components/embedded-chat/embedded-chat.model";
import {
  addAlpha,
  fromUrlSafeBase64,
  generateGuid,
  getFormattedBrainName,
  isBrowserChrome,
  replaceParamsWithValue,
} from "app/core/functions/helper-functions";
import {
  getMobileappSettingsInterface,
  isMobileSettingsInterfaceDefined,
} from "app/core/modules/mobile-interfaces/app-device-assistant-interface";
import {
  getMobileappMicrophoneInterface,
  isMicrophoneInterfaceDefined,
  postAppleMicrophonePermission,
} from "app/core/modules/mobile-interfaces/app-microphone-interface";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { ProjectTypes } from "app/enums/project-types.enum";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { BrainLLM, ChatDataRole, defaultBrainLLM, VALID_BRAIN_LLMS } from "app/pages/my-brain/my-brain.model";
import { VoiceRecognitionService } from "app/services/chrome-speech.service";
import { SocketService } from "app/services/socket.service";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { filter } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { AddMyBrainContentComponent } from "../add-my-brain-content/add-my-brain-content.component";
import { AddMyBrainContentService } from "../add-my-brain-content/add-my-brain-content.service";
import { BaseHttpComponent } from "../base-http/base-http.component";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { setBrain } from "app/store/actions/brain.action";
import { addConversation, updateLastMessage } from "app/store/actions/conversation.action";
import { getUser } from "app/store/actions/user.actions";
import { ConversationService } from "app/services/conversation.service";

@Component({
  selector: "app-ask-my-brain-search",
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    AddMyBrainContentComponent,
    MatDialogModule,
    NgFor,
    AsyncPipe,
    TranslocoModule,
    BrainLimitDirective,
    NgClass,
    NgStyle,
    FormsModule,
  ],
  templateUrl: "./ask-my-brain-search.component.html",
  styleUrls: ["./ask-my-brain-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddMyBrainContentService],
})
export class AskMyBrainSearchComponent extends BaseHttpComponent implements OnInit, OnDestroy {
  search = new FormControl<string>("", { nonNullable: true });
  userData?: UserData;
  brainId!: string;
  AppStore = AppStore;
  selectedBrain: Brain | null = null;
  addBrainContentInProgress = false;
  searchPlaceholder = "";
  chats: Conversation[] = [];
  @Input() conversationPage = false;
  @Input() activeLang = "";
  @Input() canAddContent = true;
  @Input() isEmbeddedChat = false;
  @Input() embeddedChatCreator = "";
  @Input() embeddedChatHasContent = false;
  @Input() customizeColors = false;
  @Input() customizeColorValues?: BrainStyle;
  @Input() shouldUpdateLimits = true;

  public isUserSpeaking = false;
  showCameraIcon = false;
  userDataLimits!: UserDataLimits;
  selectedLanguage!: string;
  selectedBrainLLM: BrainLLM = defaultBrainLLM;
  allowBrainLLMSelect = environment.featureFlags.allowBrainLLMSelect;
  validBrainLLMs: BrainLLM[] = VALID_BRAIN_LLMS;
  allowModuleSelect = environment.featureFlags.allowModuleSelect;
  validModules: [string, string][] = [];
  brainKey = "";
  query = "";

  constructor(
    public dialog: MatDialog,
    private appService: AppService,
    private addContentService: AddMyBrainContentService,
    public translocoService: TranslocoService,
    private voiceRecognition: VoiceRecognitionService,
    private socketService: SocketService,
    private userLimitService: UserDataLimitsService,
    private embeddingProjectService: EmbeddingProjectsService,
    private conversationService: ConversationService,
  ) {
    super();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddMyBrainContentComponent, {
      disableClose: true,
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe();
  }

  ngOnInit(): void {
    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.brainId = brain?.id;
        this.selectedBrain = brain;
      } else if (!this.router.url.includes(DASHBOARD_ROUTE)) {
        this.route.params.subscribe((params) => {
          this.brainKey = encodeURIComponent(fromUrlSafeBase64(params["id"]));
          this.getEmbeddingProject();
        });
      }
    });

    this.subs$.add(
      AppStore.askQuesFromSuggestedQuestion$.subscribe((ques) => {
        this.search.setValue(ques);
        this.sendClick();
      }),
    );

    if (this.conversationPage) {
      this.subs$.add(
        AppStore.questionAsked$.pipe(filter((ques) => !!ques)).subscribe((ques) => {
          this.search.setValue(ques);
          this.sendClick(true);
        }),
      );
    }

    this.user$.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
        this.cdr.markForCheck();
      }
    });

    this.subs$.add(
      AppStore.selectedLanguage$.subscribe((resp) => {
        this.selectedLanguage = resp;
        this.setSearchPlaceholder(this.selectedLanguage);
      }),
    );

    this.chats$.pipe(takeUntil(this.destroy$)).subscribe((chat) => {
      if (chat) {
        this.chats = chat;
        this.setSearchPlaceholder(this.selectedLanguage);
      }
    });

    if (this.allowBrainLLMSelect) {
      this.subs$.add(
        AppStore.selectedBrainLLM$.subscribe((brainLLM: BrainLLM) => {
          this.selectedBrainLLM = brainLLM;
          this.cdr.markForCheck();
        }),
      );
    }

    if (this.allowModuleSelect) {
      this.loadModules();
    }

    // Clear value if loading in the dashboard
    if (!this.conversationPage) {
      AppStore.searchValue$.next("");
    }

    // Load the saved search value from the store
    this.subs$.add(
      AppStore.searchValue$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((value) => {
        this.search.setValue(value);
      }),
    );

    // Update the store when the search value changes
    this.subs$.add(
      this.search.valueChanges
        .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(400))
        .subscribe((value) => {
          AppStore.searchValue$.next(value);
        }),
    );

    if (isBrowserChrome()) {
      this.initVoiceInput();
    }
    this.showCameraIcon = isMobileSettingsInterfaceDefined();
  }

  getEmbeddingProject() {
    this.embeddingProjectService.getSharedBrainData(this.brainKey).subscribe((resp) => {
      if (resp && resp.isSuccess && resp.data) {
        this.selectedBrain = resp.data.project;
        this.brainId = resp.data.project.id;
      }
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const isChatInProgress = AppStore.chatApiInProgress$.value.inProgress;
    const shouldSendClick =
      !isChatInProgress &&
      !this.addBrainContentInProgress &&
      this.search.value.length > 0 &&
      !this.userDataLimits.isQuestionExceeded;

    if (!this.userData?.enableSubmitOnEnterPress && event.key === "Enter" && event.shiftKey) {
      return;
    }

    if (
      (this.userData?.enableSubmitOnEnterPress && event.key === "Enter" && event.shiftKey) ||
      (!this.userData?.enableSubmitOnEnterPress && event.key === "Enter" && !event.shiftKey)
    ) {
      event.preventDefault();
      if (shouldSendClick) {
        this.sendClick();
      }
    }
  }

  async sendClick(initial = false) {
    this.query = this.search.value;
    AppStore.clearChat$.next(false);
    if (!this.query) {
      return;
    }

    AppStore.searchValue$.next("");
    this.search.setValue("");
    const conversation: Conversation = {
      project: this.selectedBrain?.id || "",
      references: [] as string[],
      role: ChatDataRole[ChatDataRole.user],
      text: this.query,
      imageUrls: [] as string[],
      creationDate: new Date(),
      id: generateGuid(),
    } as Conversation;
    this.store.dispatch(addConversation({ conversation: [conversation] }));
    if (this.conversationPage) {
      if (this.isEmbeddedChat) {
        this.addChatMessage();
        this.askQuestion(true, conversation);
        return;
      }
      this.addChatMessage();
      this.askQuestion(initial, conversation);
      // this.conversationService.create(conversation).subscribe((conversation) => {
      //   if (conversation && conversation.isSuccess && conversation.data) {
      //     this.store.dispatch(getUser());
      //     this.store.dispatch(updateLastMessage({ conversation: conversation.data }));
      //   }
      // });

      if (!this.conversationPage) {
        this.addBrainContentInProgress = true;
      }
    } else {
      if (!this.userData) return;
      this.addContentService
        .createBrain(this.userData, ProjectTypes.InternetSearchBrain, getFormattedBrainName(this.query))
        .subscribe((brain) => {
          if (brain) {
            this.store.dispatch(setBrain({ brain: brain }));
            this.navigateToMyBrain(brain.id);
          }
        });
    }
  }

  askQuestion(initial: boolean, conversation: Conversation) {
    AppStore.clearChat$.next(false);
    AppStore.chatApiInProgress$.next({ brainId: this.brainId, inProgress: true });

    if (this.selectedBrain?.projectType === ProjectTypes.ContentBrain || this.embeddedChatHasContent) {
      this.isEmbeddedChat
        ? this.appService.askQueryFromSharedBrainContent(this.query, this.embeddedChatCreator, conversation)
        : this.appService.askQueryFromBrainContent(this.query, conversation);
    } else {
      const currentBrainLLM = this.allowBrainLLMSelect ? AppStore.selectedBrainLLM$.value : defaultBrainLLM;
      const filter =
        this.allowModuleSelect && AppStore.selectedModule$.value !== "none"
          ? { module: AppStore.selectedModule$.value }
          : null;
      this.isEmbeddedChat
        ? this.appService.askQueryFromChatGPTStream(
            this.query,
            this.brainId,
            this.isEmbeddedChat,
            currentBrainLLM,
            this.embeddedChatCreator,
            null,
            filter,
          )
        : this.appService.askQueryFromChatGPTStream(
            this.query,
            this.brainId,
            this.isEmbeddedChat,
            currentBrainLLM,
            this.embeddedChatCreator,
            conversation,
            filter,
          );
    }
  }

  addChatMessage() {
    const conversation: Conversation[] = [
      // {
      //   project: this.selectedBrain?.id || "",
      //   references: [] as string[],
      //   role: ChatDataRole[ChatDataRole.user],
      //   text: this.query,
      //   imageUrls: [] as string[],
      //   creationDate: new Date(),
      //   // id: generateGuid(),
      // } as Conversation,
      {
        role: ChatDataRole.assistant,
        text: "",
        creationDate: new Date(),
        formattedContent: "",
        project: this.selectedBrain?.id || "",
      } as Conversation,
    ];
    this.store.dispatch(addConversation({ conversation: conversation }));
  }

  navigateToMyBrain(id: string) {
    if (this.query) {
      AppStore.questionAsked$.next(this.query || "");
    }
    this.router.navigate([`${replaceParamsWithValue(MY_BRAIN_ROUTE, { id })}`]);
  }

  setSearchPlaceholder(selectedLanguage: string) {
    this.translocoService.selectTranslateObject("askMyBrainSearch", {}, selectedLanguage).subscribe((result) => {
      if (this.conversationPage) {
        if (this.chats.length) {
          this.searchPlaceholder = result.askFollowUp;
        } else {
          this.searchPlaceholder = result.askQuestion;
        }
      } else {
        this.searchPlaceholder = result.searchDocumentsSite;
      }
      this.cdr.markForCheck();
    });
  }

  initVoiceInput() {
    this.voiceRecognition.init().subscribe((x) => {
      this.isUserSpeaking = false;
      this.cdr.markForCheck();
    });
    this.voiceRecognition.speechInput().subscribe((input) => {
      this.search.setValue(input.trim());
    });
  }

  startRecording() {
    this.isUserSpeaking = true;
    this.voiceRecognition.start(AppStore.selectedLanguage$.value || "en-US");
    this.notifyMobileAppForMicPermission();
  }

  stopRecording() {
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;
    this.cdr.markForCheck();
  }

  switchRecording() {
    const isChrome = isBrowserChrome();
    if (isChrome) {
      this.chromeSpeechToTextRecording();
    } else {
      this.apiSpeechToTextRecording();
      postAppleMicrophonePermission();
    }
  }

  chromeSpeechToTextRecording() {
    if (!this.isUserSpeaking) {
      this.startRecording();
    } else {
      this.stopRecording();
    }
  }

  apiSpeechToTextRecording() {
    if (!this.isUserSpeaking) {
      this.isUserSpeaking = true;
      console.log("Started recording...");
      this.socketService.connectSocket();
      this.socketService.stopRecording();
      this.socketService.initRecording(
        this.socketService.getTranscriptionConfig(),
        (data: string, isFinal: boolean) => this.handleDataReceived(data, isFinal),
        (error: unknown) => {
          console.log(error);
          this.isUserSpeaking = false;
          this.cdr.markForCheck();
          this.socketService.stopRecording();
        },
      );
    } else {
      this.socketService.stopRecording();
      this.isUserSpeaking = false;
      this.cdr.markForCheck();
    }
  }

  handleDataReceived(data: string, isFinal: boolean) {
    // Only insert the text into the search field when final transcript is received
    if (isFinal) {
      this.search.setValue(data);
      this.isUserSpeaking = false;
      this.socketService.stopRecording();
      this.cdr.markForCheck();
    }
  }

  notifyMobileAppForMicPermission() {
    if (isMicrophoneInterfaceDefined()) {
      getMobileappMicrophoneInterface().notifyMobileAppForMicPermission();
    }
  }

  openCamera() {
    this.openMobileAppNativeCamera();
  }

  openMobileAppNativeCamera() {
    if (isMobileSettingsInterfaceDefined()) {
      getMobileappSettingsInterface().onCameraOpen();
    }
  }

  onBrainLLMChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    const value = select.value as BrainLLM;
    AppStore.selectedBrainLLM$.next(value as BrainLLM);
  }

  private async loadModules(): Promise<void> {
    try {
      // Dynamic import of the modules
      const { modules } = await import("app/const/gkvi-constant");
      this.validModules = Object.entries(modules);
      this.cdr.markForCheck();
    } catch (error) {
      console.error("Failed to load modules:", error);
    }
  }

  onModuleChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    const value = select.value;
    AppStore.selectedModule$.next(value as BrainLLM);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected readonly addAlpha = addAlpha;
}
