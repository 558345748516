import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslocoService } from "@ngneat/transloco";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { environment } from "app/environments/environment";
import { MarkdownModule, MarkedOptions } from "ngx-markdown";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppService } from "./app.service";
import { httpClientConfig } from "./core/config/http-client.config";
import { CustomHttpClientModule } from "./core/modules/http-client/http-client.module";
import { CacheUpdateService } from "./services/cache-update.service";
import { TranslocoRootModule } from "./transloco-root.module";
import { StoreModule } from "@ngrx/store";
import { loadState, metaReducers } from "app/store/meta-reducers";
import { EffectsModule } from "@ngrx/effects";
import { reducers } from "app/store/store";
import { BrainEffects } from "app/store/effects/brain.effects";
import { EmbeddingFileEffects } from "app/store/effects/embedding-file.effect";
import { UserEffects } from "app/store/effects/user.effects";
import { ConversationEffects } from "app/store/effects/conversation.effects";

export function validateCache(cacheUpdateService: CacheUpdateService) {
  return () => cacheUpdateService.forceCheckForUpdates();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomHttpClientModule.forRoot(httpClientConfig),
    TranslocoRootModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      // enabled: !isDevMode(), Add this when running npm start
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.googleAnalytics,
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true, // use GitHub Flavored Markdown (GFM) specification
          breaks: true, // add <br> on a single line break
          pedantic: false, // conform to the original markdown.pl
        },
      },
    }),
    MatDialogModule,
    StoreModule.forRoot(reducers, { metaReducers, initialState: loadState() }),
    EffectsModule.forRoot([BrainEffects, EmbeddingFileEffects, UserEffects, ConversationEffects]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: validateCache,
      deps: [CacheUpdateService],
      multi: true,
    },
    TranslocoService,
    AppService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
