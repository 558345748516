import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UserDataLimits } from "app/pages/home/home.model";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { TranslocoModule } from "@ngneat/transloco";
import { ACCOUNT_DETAILS_ROUTE, IS_LIMIT_VISIBLE, LS_TOKEN } from "app/const/app-constant";
import { BaseComponent } from "app/core/components/base-component/base-component.component";
import { isAndroidApp, isAppleApp } from "app/core/modules/mobile-interfaces/app-initialization-interface";
import { LanguageService } from "app/language.service";
import { UserDataLimitsService } from "app/services/user-data-limits.service";

@Component({
  selector: "app-user-limit",
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslocoModule],
  templateUrl: "./user-limit.component.html",
  styleUrls: ["./user-limit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLimitComponent extends BaseComponent {
  @Input() isHidden = false;
  @Input() isEmbeddedChat = false;
  @Output() updateIsHidden = new EventEmitter<boolean>();
  @Output() shouldCloseDialog = new EventEmitter<boolean>();
  activeLang = "";
  userDataLimits?: UserDataLimits;
  isContentExceeded = false;
  isQuestionExceeded = false;

  constructor(
    private languageService: LanguageService,
    private userLimitService: UserDataLimitsService,
  ) {
    super();
  }

  ngOnInit() {
    this.languageService.activeLang$.subscribe((lang) => {
      this.activeLang = lang;
    });
    const storedValue = localStorage.getItem(IS_LIMIT_VISIBLE);
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      if (parsedValue.expiration > Date.now()) {
        this.isHidden = parsedValue.value;
      } else {
        localStorage.removeItem(IS_LIMIT_VISIBLE);
      }
    }
    this.user$.subscribe((userData) => {
      if (userData) {
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
      }
    });
  }

  upgradeAccount() {
    if (this.isMobileApp()) {
      window.open(
        window.location.origin +
          "/account-details?sso_token=" +
          localStorage.getItem(LS_TOKEN) +
          "&androidRedirectionRequired=true",
        "_blank",
      );
      return;
    }
    this.router.navigate([ACCOUNT_DETAILS_ROUTE]);
    this.shouldCloseDialog.emit(true);
  }

  hideMessage() {
    this.isHidden = false;
    this.updateIsHidden.emit(this.isHidden);
    const expiration = Date.now() + 24 * 60 * 60 * 1000;
    localStorage.setItem(IS_LIMIT_VISIBLE, JSON.stringify({ value: false, expiration }));
  }

  isMobileApp() {
    return isAppleApp() || isAndroidApp();
  }
}
