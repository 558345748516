export const environment = {
  environmentName: "development",
  launchdarkly: {
    clientSideID: "67654cb0552271094e931ef7"
  },
  apiKey: "098a9f3104ae508bf2fb695abc875fab",
  socketUrl: "https://api.speech.brain-demo.demo.eu.rezolve.com",
  baseUrl: "https://dev.mybrain.zone", //use this for local http://localhost:4200
  googleAnalytics: "",
  allowedFiles: [
    "pdf",
    "doc",
    "docx",
    "odt",
    "epub",
    "pages",
    "pptx",
    "xls",
    "xlsx",
    "numbers",
    "csv",
    "txt",
    "zip",
    "7z",
  ],
  brainHelpId:
    "ydj17-ipoe-rvQVRqJYP-Pvt5PwLsDBvA4vx5g2WiTRKhvlDnFqEtLJ3YK1X06FqzNRX4fJMhMr-tEydSE_fsGb0Z1Ky2wxWU8Bt7oxrESGLztS1fMZFSnr9fhdW0D67",
  pineconeIndexId: "index-my-brain-1",
  pineconeIndexName: "index-my-brain-1", // [TODO] get from database
  isPlanOnSale: false,
  dotNetBaseUrl: "https://brain-assistant-api.dev.eu2.rezolve.com", // when running .net core locally: http://localhost:5001, https://brain-assistant-api.dev.eu2.rezolve.com
  featureFlags: {
    allowBrainLLMSelect: false,
    allowModuleSelect: true,
  },
  audienceUrl: "https://dev.mybrain.zone",
};
