import { Injectable } from "@angular/core";
import {
  BrainContentType,
  ChunkMetadata,
  EmbeddingFile,
  FileCreationRequest,
  FileUploadResponse,
  GenericBAResponse,
  StatusQueryType,
} from "app/app.model";
import { AppService } from "app/app.service";
import { LS_TOKEN } from "app/const/app-constant";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "any",
})
export class EmbeddingFileService {
  private readonly CHUNK_SIZE = 10 * 1024 * 1024; // 10MB chunks
  private readonly MAX_CONCURRENT_CHUNKS = 3; // Limit parallel uploads

  constructor(
    private rawhttp: HttpClient,
    private http: HttpClientService,
    private appService: AppService,
  ) {}

  get(id: string | undefined) {
    return this.http.get<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/${id}`, { maxRetryCount: 1 });
  }
  addFiles(files: File[], projectId: string) {
    const formData = new FormData();
    files.forEach((f) => {
      formData.append("files", f, f.name);
    });
    const requestData = { projectId: projectId };
    formData.append("data", JSON.stringify(requestData));
    return this.http.post<EmbeddingFile[]>(`${environment.dotNetBaseUrl}/api/embeddingFile/files`, formData);
  }

  addFile(file: File, projectId: string) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post<EmbeddingFile>(
      `${environment.dotNetBaseUrl}/api/embeddingFile/file?brainId=${projectId}`,
      formData,
    );
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("files", file);
    return this.http.post<FileUploadResponse>(`${environment.dotNetBaseUrl}/file/upload`, formData);
  }

  uploadZipFile(formData: FormData) {
    return this.appService.uploadZipFileWithProgress(formData);
  }

  initiateFileData(creationRequest: FileCreationRequest) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/file/data`, creationRequest);
  }

  ingestFile(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/file/ingest`,
      embeddingFile,
    );
  }

  ingestFileBackground(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/file/ingest-background`,
      embeddingFile,
    );
  }

  addWebpage(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/webpage`, embeddingFile);
  }

  addWebsite(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/website`, embeddingFile, {
      maxRetryCount: 1,
    });
  }

  getBrainEmbeddingFiles(brainId: string, status: StatusQueryType, page = 0, pageSize = 100000) {
    return this.http.get<GenericBAResponse<EmbeddingFile>>(
      // eslint-disable-next-line max-len
      `${environment.dotNetBaseUrl}/api/embeddingFile/getBy?ProjectId=${brainId}&Status=${status}&page=${page}&pageSize=${pageSize}`,
    );
  }

  delete(id?: string) {
    return this.http.delete<boolean>(`${environment.dotNetBaseUrl}/api/embeddingFile?id=${id}`);
  }

  batchDelete(content: EmbeddingFile[]) {
    return this.http.delete<boolean>(`${environment.dotNetBaseUrl}/api/embeddingFile/batchDelete`, content);
  }

  countBy(id?: string) {
    return this.http.get<number>(`${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}`);
  }

  countByType(id?: string, type?: BrainContentType) {
    return this.http.get<number>(`${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}&type=${type}`);
  }

  countByStatus(id?: string, status?: StatusQueryType) {
    return this.http.get<number>(
      `${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}&status=${status}`,
    );
  }

  retry(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/retry`, embeddingFile);
  }

  // **************** Chunked file upload service: ***************

  async uploadLargeFile(file: File, projectId: string, onProgress?: (progress: number) => void): Promise<void> {
    const totalChunks = Math.ceil(file.size / this.CHUNK_SIZE);
    let uploadedChunks = 0;
    const failedChunks: number[] = [];

    try {
      // Create array of chunk indices
      const chunkIndices = Array.from({ length: totalChunks }, (_, i) => i);

      // Process chunks in parallel with limited concurrency
      while (chunkIndices.length > 0) {
        const currentBatch = chunkIndices.splice(0, this.MAX_CONCURRENT_CHUNKS);
        const uploadPromises = currentBatch.map((chunkIndex) => {
          const start = chunkIndex * this.CHUNK_SIZE;
          const end = Math.min(start + this.CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);

          const metadata: ChunkMetadata = {
            chunkIndex,
            totalChunks,
            fileName: file.name,
            projectId,
          };

          return this.uploadChunkWithRetry(chunk, metadata, 3)
            .then(() => {
              uploadedChunks++;
              if (onProgress) {
                const progress = (uploadedChunks / totalChunks) * 100;
                onProgress(progress);
              }
            })
            .catch((error) => {
              console.error(`Chunk ${chunkIndex} failed:`, error);
              failedChunks.push(chunkIndex);
              return Promise.reject(error);
            });
        });

        await Promise.allSettled(uploadPromises);
      }

      // Handle failed chunks
      if (failedChunks.length > 0) {
        throw new Error(`Upload failed for chunks: ${failedChunks.join(", ")}`);
      }

      // Notify backend that all chunks are uploaded
      await this.completeUpload({
        fileName: file.name,
        totalChunks,
        projectId,
      });
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  }

  private async uploadChunkWithRetry(chunk: Blob, metadata: ChunkMetadata, maxRetries: number): Promise<void> {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        await this.uploadChunk(chunk, metadata);
        return;
      } catch (error) {
        lastError = error as Error;
        const delay = Math.min(1000 * Math.pow(2, attempt), 5000); // Exponential backoff
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }

    throw lastError || new Error("Upload failed after retries");
  }

  private async uploadChunk(chunk: Blob, metadata: ChunkMetadata): Promise<void> {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("metadata", JSON.stringify(metadata));

    const response = await fetch(`${environment.dotNetBaseUrl}/api/EmbeddingFile/upload-chunk`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Chunk upload failed: ${response.statusText}`);
    }
  }

  private async completeUpload(metadata: { fileName: string; totalChunks: number; projectId: string }): Promise<void> {
    const response = await fetch(`${environment.dotNetBaseUrl}/api/EmbeddingFile/complete-upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(metadata),
    });

    if (!response.ok) {
      throw new Error("Failed to complete upload");
    }
  }

  private getAuthToken(): string {
    const token = localStorage.getItem(LS_TOKEN) as string;
    return token;
  }

  downloadFile(fileId: string) {
    const token = localStorage.getItem(LS_TOKEN);

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    const options = {
      headers: headers,
      responseType: "blob" as "json",
      observe: "response" as "body",
    };

    return this.rawhttp.get(`${environment.dotNetBaseUrl}/file/download?fileId=${fileId}`, options);
  }
}
