<ng-container *transloco="let t; read: 'defaultFollowUpQuestion'">
  <ng-container *ngIf="stringReferences">
    <div class="font-semibold w-fit pl-2 pr-2 pt-1 pb-1" *ngFor="let reference of stringReferences">
      <a
        class="link-reference"
        *ngIf="reference.includes('https://') || reference.includes('http://'); else plainReference"
        [href]="reference"
        target="_blank"
      >
        {{ reference | urlExtraction }}
      </a>
      <ng-template #plainReference>
        <span class="file-reference">
          {{ reference | truncate: 30 }}
        </span>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="detailedReferences">
    <div class="display-ruby">
      <div class="font-semibold w-fit pl-2 pr-2 pt-1 pb-1" *ngFor="let reference of detailedReferences">
        <a
          class="link-reference"
          *ngIf="reference.title.includes('https://') || reference.title.includes('http://'); else plainReference"
          [href]="reference.title"
          target="_blank"
        >
          {{ reference.title | urlExtraction }}
        </a>

        <ng-template #plainReference>
          <a class="file-reference" [href]="reference.link" target="_blank">
            <img src="assets/images/icons/file-ref-download.svg" alt="download-icn" />
            {{ reference.title | truncate: 30 }}{{ reference.section ? ' (p.' + reference.section + ')' : '' }}
          </a>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
